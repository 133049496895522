import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SACCPaper from "./SACCPaper";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const SACCCommitment = ({ item }) => {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <SACCPaper>
        <Grid container direction="column">
          <Grid item>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px solid black",
                marginBottom: theme.spacing(2),
              }}
            >
              {" "}
              {item.image}
            </Box>
          </Grid>
          <Grid item>
            <Typography>Commitment {item.commitment}</Typography>
            <Typography variant="h6" className={classes.title}>
              {item.name}
            </Typography>
          </Grid>
        </Grid>
      </SACCPaper>
    </Grid>
  );
};

export default SACCCommitment;
