import {Grid, Typography} from "@mui/material";
import Label from "./Label";
import {makeStyles} from "@mui/styles";
import {basicStyle} from "./CFASStyles";

const useStyles = makeStyles(() => basicStyle);

export const SectionTitle = ({title, subtitle, marginBottom}) => {
    const classes = useStyles();

    return <Grid container item direction={"column"} marginBottom={marginBottom ? marginBottom : 3}>
        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
            {title}
        </Typography>
        <Label>{subtitle}</Label>
    </Grid>;
}