import {Button, Grid, Typography} from "@mui/material";
import SACCPaper from "../SACCPaper";
import Paper from "@mui/material/Paper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles(() => ({
    statusPaper: {
        backgroundColor: "#f3f3f3",
        padding: 0,
        position: "relative",
        overflow: "hidden",
        paddingBottom: "20px"
    },
    statusBanner: {
        height: "60px",
        boxShadow: "none",
        overflow: "hidden",
        borderRadius: 0
    },
    statusText: {
        color: "white",
        fontWeight: 600,
        paddingLeft: "20px"
    },
    viewApplication: {
        color: "#FB8A3E",
        "&:hover": {
            backgroundColor: "transparent",
        },
        paddingLeft: "20px"
    },
    statusDescription: {
        color: "#424242",
        padding: "20px"
    }
}))

const CFASStatusBlock = ({ withButton, application }) => {
    const classes = useStyles();

    const colourMap = {
        "ACCEPTED": "#268636",
        "UNSUCCESSFUL": "#9a1b1b",
        "INTERVIEW": "#7b1b91",
        "SUBMITTED": "#444444"
    }

    const statusMap = {
        "SUBMITTED": "The company has not made a decision on your application yet. You will receive a notification if your application status changes.",
        "ACCEPTED": "Congratulations! The company has offered your a place for this role. They will be in contact with you directly shortly.",
        "UNSUCCESSFUL": "Your application for this role has been unsuccessful. You are still able to apply for other positions in this company, but you will not be able to submit your application for this position again.",
        "INTERVIEW": "The company has accepted you for an interview. The Careers Fair Team or the company will be in contact with you to schedule an interview slot.",
        "INTERVIEW CONFIRMED": "You have accepted an interview application from the company, please ensure you arrive on time.",
        "ACTION REQUIRED - INTERVIEW": "You have a pending interview invitation from the company, please accept it within 24 hours."
    }

    function getStatus() {
        if (application["status"] !== "INTERVIEW") {
            return application["status"]
        } else {
            if (application["interview-time"] === undefined || application["interview-time"] === "") {
                return "INTERVIEW"
            } else if (application["interview-accepted"] === true) {
                return "INTERVIEW CONFIRMED"
            } else {
                return "ACTION REQUIRED - INTERVIEW"
            }
        }
    }

    return (
        <Grid item marginTop={2} display={"flex"}>
            <SACCPaper className={classes.statusPaper} >
                <Grid container direction={"column"}>
                    <Grid item height={"100%"}>
                        <Paper className={classes.statusBanner}
                               sx={{backgroundColor: application["status"] ? colourMap[application["status"].toUpperCase()] : colourMap["SUBMITTED"]}}>
                            <Grid container direction={"column"} justifyContent={"space-evenly"} height={"100%"}>
                                <Grid item>
                                    <Typography className={classes.statusText}>
                                        {getStatus()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.statusDescription}>
                            {statusMap[getStatus()]}
                        </Typography>
                    </Grid>
                    {withButton && <Grid item>
                        <Button endIcon={<ArrowRightAltIcon/>} className={classes.viewApplication}
                                onClick={() => window.location = "/cfas/track/view-application?applicationID=" + application["application-id"]}>
                            View Details
                        </Button>
                    </Grid>}
                </Grid>
            </SACCPaper>
        </Grid>)
}

export default CFASStatusBlock;
