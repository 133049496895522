import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import SACCPaper from "./SACCPaper";
import {Grid, Typography} from "@mui/material";

const useStyles = makeStyles(() => ({
    containerNoPaper: {
        padding: theme.spacing(5)
    },
    containerPaper: {
        padding: theme.spacing(5)
    },
    logo: {
        height: "20vw",
        maxHeight: "200px",
        "-webkit-filter": "drop-shadow(5px 5px 5px #999)",
        filter: "drop-shadow(5px 5px 5px #999)"
    },
    header: {
        fontWeight: `${theme.typography.fontWeightBold} ! important`,
        color: "#FB8A3E",
        marginBottom: `${theme.spacing(4)} ! important `,
        fontSize: "32px !important"
    },
    companyName: {
        fontSize: "22px !important"
    },
    textContainer: {
        width: "100%"
    }
}));

const SACCContent = ({src, withBackground, companyName}) => {
    const classes = useStyles()

    const innerComponents = (
        <Grid container className={withBackground ? classes.containerPaper : classes.containerNoPaper} direction="row" spacing={15}  justify="space-between">
            <Grid item>
                <Grid item container direction="column" className={classes.textContainer} xs={10} display="flex" justify="center">
                    <Grid item>
                        <Typography variant="h5" className={classes.header}>
                            Presented by our main participant
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" className={classes.companyName}>
                            {companyName}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item>
                <img className={classes.logo} src={src} alt={companyName + " logo"}/>
            </Grid>
        </Grid>
    )

    if (withBackground) {
        return(
            <SACCPaper className={withBackground? null : classes.noPaper}>
                {innerComponents}
            </SACCPaper>
        )
    } else {
        return (innerComponents)
    }

}

export default SACCContent;
