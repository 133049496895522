import Avatar from "@mui/material/Avatar";

const smallIcon = {
  width: "30px",
  height: "30px",
};

const mediumIcon = {
  width: "45px",
  height: "45px",
};

const largeIcon = {
  width: "60px",
  height: "60px",
};
const getIconsize = (type) => {
  if (type === "small") {
    return smallIcon;
  } else if (type === "medium") {
    return mediumIcon;
  } else if (type === "large") {
    return largeIcon;
  }
};
const SACCIcon = ({ children = "?", type = "medium", ...rest }) => {
  return (
    <Avatar sx={getIconsize(type)} {...rest}>
      {children}
    </Avatar>
  );
};

export default SACCIcon;
