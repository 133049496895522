import React from "react";
import {Route, Routes} from "react-router-dom";
import {AuthProvider} from "./contexts/AuthContext";
import SACCNavbar from "./common/SACC/SACCNavbar";
import Home from "./pages/SACC/Home";
import Responsibility from "./pages/SACC/Responsibility";
import CareersFair from "./pages/SACC/CareersFair";
import CaseCompetition from "./pages/SACC/CaseCompetition";
import About from "./pages/SACC/About";
import Sponsors from "./pages/SACC/Sponsors";
import Workshop from "./pages/SACC/Workshops";
import CFASWrapper from "./common/SACC/CareersFairContext/CFASWrapper";
import HubLogin from "./pages/SACC/CFAS/HubLogin";
import HubRegister from "./pages/SACC/CFAS/HubRegister";
import HubHome from "./pages/SACC/CFAS/HubHome";
import Unsubscribe from "./pages/SACC/CFAS/Unsubscribe";
import ApplyHome from "./pages/SACC/CFAS/ApplyHome";
import ApplyCompany from "./pages/SACC/CFAS/ApplyCompany";
import ApplySubmit from "./pages/SACC/CFAS/ApplySubmit";
import Track from "./pages/SACC/CFAS/Track";
import TrackViewApplication from "./pages/SACC/CFAS/TrackViewApplication";
import CompanyLogin from "./pages/SACC/CFAS/CompanyLogin";
import CompanyHome from "./pages/SACC/CFAS/CompanyHome";
import ProfileForCompany from "./pages/SACC/CFAS/CompanyViewProfile";
import CompanyViewQR from "./pages/SACC/CFAS/CompanyViewQR";
import EventRegister from "./pages/SACC/CFAS/EventRegister";
import EventHome from "./pages/SACC/CFAS/EventHome";
import AbstractSubmit from "./pages/SACC/Abstract/AbstractSubmit";
import {Grid, useMediaQuery} from "@mui/material";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";
import {PropsWithChildren, useState, useEffect} from "react";
import theme from "./theme";
import {checkToken} from "./TSsupport";
import {Callback} from "./pages/SACC/CFAS/Callback";
import {SamaggiPageContainer} from "./common/SamaggiPageContainer";
import {PaperBackdrop} from "./common/SamaggiBackdrop";

export interface BasicProps {
    mobile: boolean,
    vertical: boolean,
    setShowCover: React.Dispatch<React.SetStateAction<boolean>>
}

export interface MinimalProps {
    setShowCover: React.Dispatch<React.SetStateAction<boolean>>
}


export const Main: React.FC<PropsWithChildren<any>> = () => {
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const vertical = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    const navigate = useNavigate();
    const [params,] = useSearchParams();
    const medScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [showCover, setShowCover] = useState(false);
    const [mainShowCover, setMainShowCover] = useState(false);

    useEffect(() => {
        console.log("Added event listener.")
        window.addEventListener("storage", () => {
            const token = localStorage.getItem("token");
            console.log("UPDATED!", location.pathname)
            if (location.pathname !== "/callback" && token !== null) {
                if (!location.pathname.includes("cfas") || location.pathname.includes("/company/")) {
                    setMainShowCover(false);
                    return;
                }
                setMainShowCover(true);
                doTokenCheck()
            } else if (location.pathname === "/cfas/hub/sign-in" && token === null) {
                setMainShowCover(false);
            }
        }) // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function doTokenCheck() {
        if (!location.pathname.includes("cfas") || location.pathname.includes("/company/")) {
            return;
        }
        if (!["/cfas/hub/sign-in", "/cfas/hub/callback"].includes(location.pathname) && localStorage.getItem("token") !== null) {
            authenticate();
        }
        checkToken().then((valid) => {
            console.log("DO TOKEN CHECK", valid, location.search)
            setMainShowCover(false);
            if (!valid && !["/cfas/hub/sign-in", "/cfas/hub/callback"].includes(location.pathname)) {
                const url = new URL(window.location.protocol + '//' + window.location.host + "/cfas/hub/sign-in")

                if (location.pathname !== "/") {
                    url.searchParams.set("nav", location.pathname);
                }

                params.forEach((value, key) => {
                    if (key !== "nav" && key !== "req") {
                        url.searchParams.set(key, value);
                    }
                })

                window.location.href = url.href;
            } else if (valid && location.pathname === "/cfas/hub/sign-in") {
                if (params.has("nav")) {
                    const cleanedParams = params;
                    const nav = (params.get("nav") as string).replace("$", "/");
                    cleanedParams.delete("nav");

                    console.log("NAV1")

                    navigate({
                        pathname: nav,
                        search: cleanedParams.toString()
                    });
                } else {
                    console.log("NAV2")

                    navigate({
                        pathname: "cfas/hub/home",
                        search: params.toString()
                    });
                }
            } else {
                console.log("NAV3", location.pathname)
            }
        })
    }

    useEffect(() => {
        console.log('Location changed', location.pathname);
        doTokenCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    function authenticate() {
        // Additional Authentication
    }

    return (
        <>
            <div style={medScreen ? {zoom: "100%"} : {zoom: "50%"}}>
                <AuthProvider>
                    <SACCNavbar/>
                    <PaperBackdrop open={showCover || mainShowCover} noContainer={true}>
                        <Routes>
                            <Route path='/' element={<Home/>}></Route>
                            <Route path='/responsibility' element={<Responsibility/>}></Route>
                            <Route path='/careers-fair' element={<CareersFair/>}></Route>
                            <Route path='/case-competition' element={<CaseCompetition/>}></Route>
                            <Route path='/about' element={<About/>}></Route>
                            <Route path='/sponsors' element={<Sponsors/>}></Route>
                            <Route path='/workshops' element={<Workshop/>}></Route>
                            <Route path='/cfas/hub/sign-in' element={<Grid container direction={"column"} alignItems={"center"}><SamaggiPageContainer><HubLogin mobile={mobile} vertical={vertical} setShowCover={setShowCover}/></SamaggiPageContainer></Grid>}></Route>
                            <Route path='/cfas/hub/callback' element={<CFASWrapper><Callback mobile={mobile} vertical={vertical} setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/hub/register' element={<CFASWrapper><HubRegister setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/hub/home' element={<CFASWrapper><HubHome setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/hub/unsubscribe' element={<CFASWrapper><Unsubscribe/></CFASWrapper>}></Route>
                            <Route path='/cfas/apply/home' element={<CFASWrapper><ApplyHome setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/apply/company' element={<CFASWrapper><ApplyCompany setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/apply/submit' element={<CFASWrapper><ApplySubmit setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/track/home' element={<CFASWrapper><Track setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/track/view-application' element={<CFASWrapper><TrackViewApplication setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/company/sign-in' element={<CFASWrapper><CompanyLogin/></CFASWrapper>}></Route>
                            <Route path='/cfas/company/home' element={<CompanyHome/>}></Route>
                            <Route path='/cfas/company/view-application' element={<CFASWrapper><ProfileForCompany setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/cfas/company/view-qr' element={<CFASWrapper><CompanyViewQR/></CFASWrapper>}></Route>
                            <Route path='/cfas/event/register' element={<CFASWrapper><EventRegister/></CFASWrapper>}></Route>
                            <Route path='/cfas/event/home' element={<CFASWrapper><EventHome setShowCover={setShowCover}/></CFASWrapper>}></Route>
                            <Route path='/abstract' element={<CFASWrapper><AbstractSubmit/></CFASWrapper>}></Route>
                        </Routes>
                    </PaperBackdrop>
                </AuthProvider>
            </div>
        </>
    )
}
