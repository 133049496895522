import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect, useState} from "react";
import { useAuth } from "../../../contexts/AuthContext";
import CFASStatusBlock from "../../../common/SACC/CareersFairContext/CFASStatusBlock";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {MinimalProps} from "../../../Main";
import {SamaggiTypography} from "../../../common/SamaggiTypography";
import {SamaggiButton} from "../../../common/SamaggiButton";


const useStyles = makeStyles(() => basicStyle);

const answerStyle = {
    fontWeight : "400",
    borderRadius : "15px",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    height: 200
}

const TrackViewApplication = (props) => {

    const {viewApplication, acceptInterview} = useAuth();

    const classes = useStyles();

    const [cvLink, setCvLink] = useState("");
    const [coverLink, setCoverLink] = useState("");
    const [profileData, setProfileData] = useState({});
    const [applicationData, setApplicationData] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [positionData, setPositionData] = useState({});
    const [lastUpdated, setLastUpdated] = useState("");
    const [message, setMessage] = useState("");

    function handleCVButton() {
        if (cvLink !== "") {
            console.log(cvLink);
            window.open(cvLink, "_blank");
        }
    }

    function handleCoverButton() {
        if (coverLink !== "") {
            console.log(coverLink);
            window.open(coverLink, "_blank");
        }
    }

    function getData(applicationID) {
        viewApplication(applicationID).then(async (res) => {
            const data = await res.json()
            if (res.status === 200) {
                setCvLink(data.data["cvLink"]);

                if (Object.keys(data.data).includes("application_data")) {
                    setApplicationData(data.data["application_data"]);
                    const tApplication = data.data["application_data"];
                    setCoverLink(tApplication["coverFile"]);
                    setMessage(tApplication["message"]);

                    const d = new Date(0);
                    d.setUTCSeconds(tApplication["message-date"]);
                    setLastUpdated(d.toString());
                }
                if (Object.keys(data.data).includes("profile_data")) {
                    setProfileData(data.data["profile_data"]);
                    setCvLink(data.data["profile_data"]["cvLink"]);
                }

                if (Object.keys(data.data).includes("position_data")) {
                    setPositionData(data.data["position_data"]);
                }

                props.setShowCover(false);
                console.log(profileData);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    useEffect(() => {
        if (firstLoad) {
            props.setShowCover(true);

            const urlParams = new URLSearchParams(window.location.search);
            const applicationID = urlParams.get('applicationID');

            if (applicationID !== null) {
                getData(applicationID);
            } else {
                alert("Unable to load application.")
            }

            setFirstLoad(false);
        }
    })

    function acceptInterviewButton() {
        acceptInterview(applicationData["application-id"]).then(async (res) => {
            const data = await res.json()

            if (res.status === 200) {
                alert("Interview Accepted");
                window.location.reload();
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    function pageLayout(i) {
        let tiles = []
        tiles.push(0);

        if (applicationData["interview-time"] !== undefined && applicationData["interview-time"] !== "") {
            tiles.push(1);
        }

        tiles.push(2);

        if (coverLink && coverLink !== "X") {
            tiles.push(3)
        }

        if (message && message !== "") {
            tiles.push(4)
        }

        return tiles.length % 2 === 0 ? 6 : ((i === tiles[tiles.length - 1]) ? 12 : 6)
    }

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                padding="100px"
                rowSpacing={5}
            >
                <CFASHeader secondaryText={"Back to List"} secondaryURL={"/cfas/track/home"}>
                    CFAS Track
                </CFASHeader>
                <Grid item container direction="row" columnSpacing={6} rowSpacing={6} xs={12}>
                    <Grid item xs={12}>
                        <SACCPaper>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                Application Information
                            </Typography>
                            <Grid container direction={largeScreen? "row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                                <Grid container item xs={6} direction="column">
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Position
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {positionData["position-name"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction="column">
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Application ID
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text} sx={{wordWrap: "break-word", wordBreak: "break-word"}}>
                                            {applicationData["application-id"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} direction="column">
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Additional Text
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text} sx={{whiteSpace: "pre-wrap"}}>
                                            {applicationData["additional-text"] === "" ? "You did not enter any additional text" : applicationData["additional-text"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    <Grid item xs={12} xl={pageLayout(0)}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction="column" rowSpacing={3} style={{height:'100%', boxSizing: "border-box", display: 'flex', flexDirection: 'column'}}>
                                <Grid item style={{flex: "none", alignSelf: "start"}}>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Application Status
                                    </Typography>
                                </Grid>
                                <Grid item display={"flex"} style={{alignSelf: "stretch", flexGrow: 1}}>
                                    <CFASStatusBlock application={applicationData}/>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    { (applicationData["interview-time"] !== undefined && applicationData["interview-time"] !== "") && <Grid item xs={12} xl={pageLayout(1)}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction="column" rowSpacing={3}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Interview Invitation
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    { applicationData["interview-accepted"] && <Typography gutterBottom variant="h6" className={classes.text}>
                                        You have accepted the interview invitation, please contact us if you are no longer able to attend. Your interview time is:
                                    </Typography>}
                                    { !applicationData["interview-accepted"] && <Typography gutterBottom variant="h6" className={classes.text}>
                                        The company has invited you for an interview, please respond to this within 24 hours, you may lose your interview slot otherwise.
                                    </Typography>}
                                </Grid>
                                <Grid item>
                                    <SamaggiTypography center bold large>{applicationData["interview-time"]}</SamaggiTypography>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <SamaggiTypography><SamaggiTypography bold inline>Interviewer</SamaggiTypography>: {applicationData["interviewer"]}</SamaggiTypography>
                                    </Grid>
                                    <Grid item>
                                        <SamaggiTypography><SamaggiTypography bold inline>Location</SamaggiTypography>: {applicationData["location"]}</SamaggiTypography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" spacing={1}>
                                    { !applicationData["interview-accepted"] && (
                                        <Grid item>
                                            <SamaggiButton fullWidth colour={"#268636"} onClick={() => acceptInterviewButton()}>Accept Invitation</SamaggiButton>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <SamaggiButton fullWidth onClick={() => {window.location.href = "mailto:careers-fair@samaggisamagom.com"}}>Contact Us</SamaggiButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>}
                    <Grid item xs={12} xl={pageLayout(2)}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction="column" rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction="column" rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Submitted CV
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download the copy of CV that the company received.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleCVButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    { (coverLink && coverLink !== "X") && <Grid item xs={12} xl={pageLayout(3)}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction="column" rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction="column" rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Application Cover Letter
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download and view a copy of the attached cover letter.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleCoverButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>}
                    { (message && message !== "") && <Grid item xs={12} xl={pageLayout(4)}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction="column" rowSpacing={3}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Message from the Company
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        The following message was sent to you by the company. This message was last updated at: {lastUpdated}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography style={answerStyle} sx={{whiteSpace: "pre-wrap"}}> {message}</Typography>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default TrackViewApplication;