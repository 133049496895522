import theme from "../../../theme"
import {Grid, Button, ThemeProvider, Typography} from "@mui/material"
import InputField from "../../../common/SACC/CareersFairContext/InputField";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown"
import Label from "../../../common/SACC/CareersFairContext/Label"
import {UUIDv4} from "../../../support";
import {useEffect, useState} from "react";
import AWS from "aws-sdk"
import {yearList, phoneMap, companyConnectList, courseLevelList, ssoCourseAreaList} from "../../../assets/options";
import SACCPaper from "../../../common/SACC/SACCPaper";
import Upload from "../../../common/SACC/CareersFairContext/Upload";
import {makeStyles} from "@mui/styles";
import { useAuth } from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {SectionTitle} from "../../../common/SACC/CareersFairContext/SectionTitle";
import {signOut, sortedUniversityNames} from "../../../TSsupport";

const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: "700",
        fontSize: "32px",
    },
    logo: {
        width: "50px",
    },
    logoffButton: {
        background: "#FB8A3E !important",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px !important",
        "&:hover": {
            background: "#FB8A3E",
        },
        marginTop: "auto",
        marginBottom: "auto"
    },
    submitButton: {
        background: "#FB8A3E",
        color: "white",
        width: "50%",
        padding: "10px 0",
        fontSize: "24px",
        borderRadius: "15px"
    }
}));

const HubRegister = (props) => {

    const reverseMapping = o => Object.keys(o).reduce((r, k) =>
        Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }), {})

    const classes = useStyles();

    const [fullName, setFullName] = useState("");
    const [nickname, setNickname] = useState("");
    const [yearOfStudy, setYearOfStudy] = useState("-");
    const [courseArea, setCourseArea] = useState("");
    const [level, setLevel] = useState("");
    const [courseName, setCourseName] = useState("");
    const [lineID, setLineID] = useState("");
    const [countryCode, setCountryCode] = useState("44");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [fileLink, setFileLink] = useState("");
    const [phoneMapRev, setPhoneMapRev] = useState({});
    const [countries, setCountries] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [profileExists, setProfileExists] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [dob, setDOB] = useState("");
    const [companyConnect, setCompanyConnect] = useState("-SELECT AN OPTION-XXX");
    const [universitySelect, setUniversitySelect] = useState("");
    const [universityValue, setUniversityValue] = useState("");
    const {setProfile, getProfile} = useAuth();

    function onChangeFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }

    function getData() {
        props.setShowCover(true);
        // checkToken("/cfas/hub/home", userID, tokenID, secret);

        getProfile(true).then(async (res) => {
            setLoadingProfile(false);
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                setFullName(data.data["fullName"]);
                setNickname(data.data["nickname"]);
                setYearOfStudy(data.data["year"]);
                setCourseArea(data.data["courseArea"]);
                setLevel(data.data["level"]);
                setCourseName(data.data["courseName"]);
                setLineID(data.data["lineID"]);
                setCountryCode(data.data["phoneNumber"].split("//")[0] === "" ? '44' : data.data["phoneNumber"].split("//")[0]);
                setPhoneNumber(data.data["phoneNumber"].split("//")[1]);
                setGender(data.data["gender"]);
                setFileLink(data.data["cvLink"]);
                setProfileExists(true);
                setCompanyConnect(data.data["companyConnect"] ? companyConnectList[1] : (data.data["companyConnect"] === "" ? companyConnectList[0] : companyConnectList[2]));

                if (data.data["university"].includes("//")) {
                    setUniversitySelect("Other");
                    setUniversityValue(data.data["university"].replace("Other//", ""));
                } else {
                    setUniversitySelect(data.data["university"]);
                    setUniversityValue(data.data["university"]);
                }

                setDOB(data.data["birthdate"]);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                console.log("New application");
            } else {
                alert(data.data["reason"]);
                if (data.data["reason"] === "Re-login Required") {
                    signOut();
                    localStorage.setItem("usePermsLink", "YES")
                }
            }
            props.setShowCover(false);
        }).catch((error) => {
            setLoadingProfile(false);
            console.log(error)
            props.setShowCover(false);
        })
    }

    function submitHandler() {
        const cfasUserCvBucket = "cfas-user-cv-bucket";
        const bucketRegion = "eu-west-2";
        const IdentityPoolId = "eu-west-2:67102926-3c2f-4e94-b91a-8058ee31d8b8";

        if (submitting) {
            return
        }

        console.log(companyConnect);

        if (nickname === "" || fullName === "" || yearOfStudy === "-" || courseArea === "" || level === "" || courseName === "" || lineID === "" || phoneNumber === "" || gender === "" || companyConnect === "-SELECT AN OPTION-" || universityValue === "") {
            alert("Please fill in all the fields");
            return;
        }

        if (isNaN(+phoneNumber) === true) {
            alert("Invalid Phone Number");
            return;
        }

        setSubmitting(true);

        if (fileLink !== "" || fileName === "") {
            saveToDB("", "");
            return
        }

        AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IdentityPoolId
            })
        });

        const key = encodeURI(UUIDv4() + fileName.replace(/[^A-Za-z0-9.]/g, ""));

        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: cfasUserCvBucket,
                Key: key,
                Body: file
            }
        });

        const uploadPromise = upload.promise();

        uploadPromise.then(
            () => saveToDB(cfasUserCvBucket, key),
            function(err) {
                alert(err);
                setSubmitting(false);
                return alert("There was an error uploading your photo: " + err.message);
            }
        );
    }

    function saveToDB(bucket, key) {
        let cvLink;

        if (fileLink === "" && bucket !== "") {
            cvLink = "https://" + bucket + ".s3.eu-west-2.amazonaws.com/" + key;
        } else if (fileLink === "" && bucket === "") {
            cvLink = "";
        } else if (fileLink !== "") {
            cvLink = fileLink;
        } else {
            cvLink = "ERR!";
        }

        const formData = {
            "fullName": fullName,
            "nickname": nickname,
            "year": yearOfStudy,
            "courseArea": courseArea,
            "level": level,
            "courseName": courseName,
            "lineID": lineID,
            "phoneNumber": countryCode.replace("+", "") + "//" + (phoneNumber - 0).toString(),
            "gender": gender,
            "cvLink": cvLink,
            "companyConnect": companyConnect === companyConnectList[1],
            "university": universityValue,
            "birthdate": dob
        }

        setProfile(formData).then(async (res) => {
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                window.location = "/cfas/hub/home";
            } else {
                alert(data.data["reason"]);
                setSubmitting(false);
            }
        }).catch((error) => {
            console.log(error)
            setSubmitting(false);
        })
    }

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            getData();
            setPhoneMapRev(reverseMapping(phoneMap));
            setCountries(Object.keys(phoneMap));
            setFirstLoad(false);
        }
    })

    return (
        <ThemeProvider theme={theme}>
            {!loadingProfile && <Grid container direction="row" justifyContent="center">
                <Grid container item direction="column" justifyContent="center" padding="100px" spacing={4} sx={{maxWidth: 1600}}>
                    <Grid container item justifyContent="space-between" rowSpacing={3}>
                        <CFASHeader classes={classes} secondaryURL={"/cfas/hub/home"} secondaryText={profileExists ? "Back to Home" : undefined}>
                            CFAS Apply
                        </CFASHeader>
                    </Grid>
                    <Grid item sx={{paddingTop: 20}}>
                        <Grid container spacing={5}>
                            <Grid container item direction={"row"} spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Given Name(s) and Last Name </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} disabled/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Nickname </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Date of Birth </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={dob} onChange={(e) => setDOB(e.target.value)} disabled/>
                                </Grid>
                            </Grid>
                            <Grid container item direction={"row"} spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={(universitySelect === "Other") ? 6 : 12}>
                                    <Grid item>
                                        <Label> Latest University </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={sortedUniversityNames()} value={universitySelect} onChange={(e) => {setUniversitySelect(e.target.value); (e.target.value === "Other") ? setUniversityValue("") : setUniversityValue(e.target.value)}}/>
                                    </Grid>
                                </Grid>
                                {(universitySelect === "Other") &&  <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Enter University Name </Label>
                                    </Grid>
                                    <Grid item>
                                        <InputField fullWidth type="text" value={universityValue} onChange={(e) => setUniversityValue(e.target.value)}/>
                                    </Grid>
                                </Grid>}
                            </Grid>
                            <Grid container item direction={"row"} spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Gender </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={["Male", "Female", "Other", "Prefer not to Say"]} value={gender} onChange={(e) => setGender(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Year of Study </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={yearList} value={yearOfStudy} onChange={(e) => setYearOfStudy(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Course Area </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={ssoCourseAreaList} value={courseArea} onChange={(e) => setCourseArea(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Level </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={courseLevelList} value={level} onChange={(e) => setLevel(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Course Name </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Line ID </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={lineID} onChange={(e) => setLineID(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={5}>
                                    <Grid item>
                                        <Label> Country Code </Label>
                                    </Grid>
                                    <Grid item >
                                        {countries[0] !== undefined && <Dropdown items={countries} value={countryCode !== "" ? phoneMapRev[countryCode][0] : countries[0]} onChange={(e) => {setCountryCode(phoneMap[e.target.value]); console.log(phoneMap[e.target.value])}}/>}
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={7}>
                                    <Grid item>
                                        <Label> Phone Number </Label>
                                    </Grid>
                                    <Grid item >
                                        <InputField fullWidth type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {fileLink === "" && <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>CV File</Label>
                                </Grid>
                                <Grid item>
                                    <SACCPaper>
                                        <Upload handleChange={e => onChangeFile(e)}></Upload>
                                    </SACCPaper>
                                </Grid>
                            </Grid>}
                            <Grid item container direction={"column"} spacing={5}>
                                <Grid item container direction={"column"}>
                                    <SectionTitle title={"Sign Up to CompanyConnect?"}/>
                                    <Grid container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>
                                                CFAS CompanyConnect is a service that helps you find the job or internships that fits you. Register to CompanyConnect and your profile will be available to companies who are currently recruiting and are looking for someone like you!
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>
                                                Once you register CompanyConnect will send your CFAS Profile (all information you have entered on any of the CFAS services) to relevant companies who will consider your profile and get in contact with you if your profile interests them.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid item>
                                                <Dropdown items={companyConnectList} value={companyConnect} setter={setCompanyConnect} onChange={(e) => setCompanyConnect(e.target.value)}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.text}>By signing up to CompanyConnect, I consent to allow Samaggi Samagom to send my CFAS profile information to companies who are recruiting through CFAS <a href={""} onClick={() => window.open("/cfas/apply/home", "_blank")}>(see list)</a>. I am aware I may receive communications from companies involved and that I may need to contact each company directly to stop receiving communications from them. I may withdraw from CompanyConnect at any time.<br/></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item justifyContent="center" marginTop={fileLink === "" ? 0 : 3}>
                                <Button className={classes.submitButton} type="submit" onClick={submitHandler}> {fileLink === "" ? (submitting ? "Submitting..." : "Submit") : (submitting ? "Saving..." : "Save")} </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </ThemeProvider>
    )
}

export default HubRegister