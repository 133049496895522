import {BrowserRouter as Router} from "react-router-dom"
import theme from "./theme";
import React from "react";
import {ThemeProvider} from "@mui/styles";
import {Main} from "./Main";

const App = () => {
  return (
      <ThemeProvider theme={theme}>
          <Router>
              <div style={{zoom: "100%", backgroundColor: "#fbfbfb", height: "100vh", overflow: "auto"}}>
                  <Main/>
              </div>
          </Router>
      </ThemeProvider>
  );
};

export default App;
