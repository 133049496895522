import React, {useEffect} from "react";
import {BasicProps} from "../../../Main";
import {useNavigate, useSearchParams} from "react-router-dom";

export const Callback: React.FC<BasicProps> = (props) => {
    const [params, ] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.has("token")) {
            alert("Sign in was not successful.")
        } else {
            localStorage.setItem("token", params.get("token") as string);

            window.dispatchEvent(new Event("sso-state"));

            window.close();

            props.setShowCover(true);

            setTimeout(() => {
                if (params.has("nav")) {
                    const cleanedParams = params;
                    const nav = params.get("nav") as string;
                    cleanedParams.delete("nav");
                    cleanedParams.delete("token");

                    console.log(nav)

                    navigate({
                        pathname: nav,
                        search: cleanedParams.toString()
                    });
                } else {
                    const cleanedParams = params;
                    cleanedParams.delete("token");

                    navigate({
                        pathname: "/cfas/hub/home",
                        search: cleanedParams.toString()
                    });
                }
            }, 5000)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <></>
    )
}