import Typography from "@mui/material/Typography";
import theme from "../../theme";
import SACCPaper from "./SACCPaper";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import SACCTextButton from "./SACCTextButton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    fontSize: "24px",
  },
  date: {
    fontWeight: `${theme.typography.fontWeightLight} ! important`,
    fontSize: "14px",
  },
  overviewContainer: {
    padding: theme.spacing(5),
    height: "calc(100% - 200px)",
  },
  overviewPaper: {
    borderRadius: `${theme.shape.borderRadius * 5}px ! important`,
  },
}));

const SACCOverview = ({ item }) => {
  const classes = useStyles();
  return (
    <Grid item container xs={12} lg={3}>
      <Paper className={classes.overviewPaper} elevation={6}>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            backgroundColor: "gray",
            borderTopLeftRadius: `${
              theme.shape.borderRadius * 5
            }px ! important`,
            borderTopRightRadius: `${
              theme.shape.borderRadius * 5
            }px ! important`,
          }}
        />

        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={classes.overviewContainer}
        >
          <Grid container item direction="column" rowSpacing={2}>
            <Grid item>
              <Typography variant="p" className={classes.title}>
                {item.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="p">{item.date}</Typography>
            </Grid>

            <Grid item>
              {item.live === "Now Open" ? (
                <SACCTextButton type="open">{item.live}</SACCTextButton>
              ) : (
                <SACCTextButton type="upcoming">{item.live}</SACCTextButton>
              )}
            </Grid>
            <Grid item>
              <Typography variant="p">{item.description}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <SACCTextButton type="learnMore" endIcon={<ArrowRightAltIcon />}>
              Learn More
            </SACCTextButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SACCOverview;
