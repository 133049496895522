import Grid from "@mui/material/Grid";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import SACCDivider from "../../common/SACC/SACCDivider";
import SACCSponsorEvent from "../../common/SACC/SACCSponsorEvent";
import SACCSponsorTier from "../../common/SACC/SACCSponsorTier";
import SACCContent from "../../common/SACC/SACCContent";
import SACCFooter from "../../common/SACC/SACCFooter";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const placeholder =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
const sponsorEvents = [
  {
    sponsor: "Sponsor 1 Name",
    event: "Workshop 1",
    description: placeholder,
  },
  {
    sponsor: "Sponsor 2 Name",
    event: "Workshop 2",
    description: placeholder,
  },
  {
    sponsor: "Sponsor 3 Name",
    event: "Workshop 3",
    description: placeholder,
  },
];

const sponsorTier = [
  {
    tier: "Platinum",
    companies: ["Company A", "Company B"],
  },
  {
    tier: "Gold",
    companies: ["Company C", "Company D"],
  },
  {
    tier: "Silver",
    companies: ["Company E", "Company F"],
  },
  {
    tier: "Other Sponsors",
    companies: [
      "Company G",
      "Company H",
      "Company G",
      "Company H",
      "Company G",
      "Company H",
      "Company G",
    ],
  },
];

const Sponsor = () => {
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <SACCPageHeader />
      </Grid>
      <SACCContent padded>
        <Grid item>
          <Typography
            align="center"
            gutterBottom
            variant="h6"
            className={classes.title}
          >
            Introduction
          </Typography>
          <Typography align="center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur.
          </Typography>
        </Grid>
        <SACCDivider />

        <Grid item container justifyContent="center" spacing={10}>
          {sponsorEvents.map((item) => (
            <SACCSponsorEvent item={item} />
          ))}
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            {" "}
            Level of Sponsors
          </Typography>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>
        <Grid item container direction="column" rowSpacing={5}>
          {sponsorTier.map((item) => "Temporary Unavailable")}
        </Grid>
      </SACCContent>
      <SACCFooter />
    </Grid>
  );
};

export default Sponsor;
