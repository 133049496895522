import {ThemeProvider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import SACCContent from "../../common/SACC/SACCContent";
import SACCCareersPaper from "../../common/SACC/SACCCareersPaper";
import SACCFooter from "../../common/SACC/SACCFooter";
import CaseCompObjectives from "../../common/SACC/CaseCompetition/CaseCompObjectives";
import SACCPaper from "../../common/SACC/SACCPaper";
import {Colours, SamaggiTypography} from "../../common/SamaggiTypography";
import {SamaggiButton} from "../../common/SamaggiButton";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  content: {
    padding: theme.spacing(10, 25),
  },
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    color : "#FB8A3E"
  },
  sponsorPaper: {
    padding: theme.spacing(5),
    borderRadius: `${theme.shape.borderRadius * 5}px ! important`,
  },
}));

const sponsorInfo = [
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/RGB_Logo-Eng-Thai-Vertical+-+Ddochi+Coco.jpg",
        name: "Krungthai"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/AIS",
        name: "AIS"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Logo_Bangkok_Bank_Public_Company_Limited.svg.png",
        name: "Bangkok Bank"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Banpu_Logo+-+Thanchanok+Rattanapanth.png",
        name: "Banpu"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Logo_CPALL.svg.png",
        name: "CP All"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/cpf.png",
        name: "CP Foods"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/CPG+logo.png",
        name: "CP Group"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Gulf+logo.png",
        name: "GULF Energy"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Logo-1.png",
        name: "Lotus's"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/makro-logo-vector.png",
        name: "Makro and Makro Pro"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/nexant_logo.png",
        name: "NexantECA"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Starboard-Blue-Team-Partner-SCG-Logo-Cover.jpg",
        name: "SCG"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/1280px-True_Corporation_(Thailand).svg.png",
        name: "True Corporation"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/BOT-HQ.jpg",
        name: "Bank of Thailand"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/Thai+Union.png",
        name: "Thai Union"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/TDRI+logo.jpg",
        name: "TDRI"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/uob.jpeg",
        name: "UOB"
    },
    {
        logo: "https://cfas-assets.s3.eu-west-2.amazonaws.com/YIP+IN+TSOI+LOGO.png",
        name: "Yip In Tsoi"
    }
]

const speakers = [
    {
        name : "Dr. Somkiat Tangkitvanich",
        image : "https://cfas-assets.s3.eu-west-2.amazonaws.com/Dr+Somkiat.png",
        time: "10:50am - 11:45am",
        time2: "10:00am - 10:50am"
    },
    {
        name : "Mr. Thanathorn Juangroongruangkit",
        image : "https://cfas-assets.s3.eu-west-2.amazonaws.com/thanathorn.jpg",
        time: "1:45pm - 2:40pm",
        time2: "2:40pm - 4:00pm"
    }
]


const CareersFair = () => {
    const classes = useStyles();

    useEffect(() => {
        window.location.href = "https://sacc.samaggisamagom.com/cfas"
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column">
                <Grid item>
                    <SACCPageHeader />
                </Grid>
                <SACCContent>
                    <Grid item container direction="row" spacing={4} >
                        <Grid item xs={12} md={8} sx={{ ml: { xs: 4, md: 8 }, mr: {xs: 4, md: 0}}}>
                            <SACCCareersPaper/>
                        </Grid>
                        <Grid item xs={12} md={3} display="flex" sx={{ mx: { xs: 4, md: 0 }}}>
                            <SACCPaper className={classes.border} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <Grid container direction="column" style={{ flex: 1 }} spacing={3}>
                                    <Grid item>
                                        <SamaggiTypography bold large colour={Colours.samaggiOrange}>Apply Now before 14th January!</SamaggiTypography>
                                    </Grid>
                                    <Grid item>
                                        <SamaggiTypography>Careers Fair Application System (CFAS) let's you apply to all participating companies in just a few clicks! Applications are now open, apply before Sunday 14th January for a chance to be interviewed during the Careers Fair event.</SamaggiTypography>
                                    </Grid>
                                </Grid>
                                <SamaggiButton fullWidth style={{height: "50px", marginTop: "20px"}} onClick={() => {window.location.href = "sacc.samaggisamagom.com/cfas"}}>Go to SamaggiCFAS</SamaggiButton>
                            </SACCPaper>
                        </Grid>
                    </Grid>
                    <CaseCompObjectives/>
                    <Grid item container spacing={3}>
                        <Grid item marginX={8}>
                            <SamaggiTypography bold xlarge colour={Colours.samaggiOrange}>Seminar</SamaggiTypography>
                        </Grid>
                        <Grid item container spacing={5} flexWrap="wrap" marginX={8} style={{ display: 'flex', alignItems: 'stretch' }}>
                            <Grid item xs={12} md={4} display={"flex"}>
                                <SACCPaper style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Grid container direction="column" style={{ flex: 1 }} spacing={3}>
                                        <Grid item>
                                            <SamaggiTypography bold large colour={Colours.samaggiOrange}>Interested in Samaggi Seminar?</SamaggiTypography>
                                        </Grid>
                                        <Grid item>
                                            <SamaggiTypography>Sign up through CFAS now to ensure your place at the event. You will need an account to attend both the Careers Fair and the Seminar.</SamaggiTypography>
                                        </Grid>
                                    </Grid>
                                    <SamaggiButton fullWidth style={{height: "50px", marginTop: "20px"}} onClick={() => {window.location.href = "sacc.samaggisamagom.com/cfas"}}>Register on SamaggiCFAS</SamaggiButton>
                                </SACCPaper>
                            </Grid>

                            {speakers.map((speaker) => (
                                <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', alignItems: 'stretch' }}>
                                    <SACCPaper style={{ borderRadius: '10px', overflow: 'hidden', padding: 0, aspectRatio: "1"}} display={"flex"}>
                                        <div style={{ backgroundColor: '#f0f0f0' }}>
                                            <img
                                                src={speaker.image}
                                                alt={speaker.name}
                                                style={{
                                                    width: '100%',
                                                    height: "80%",
                                                    objectFit: 'contain',
                                                    borderTopLeftRadius: '10px',
                                                    borderTopRightRadius: '10px'
                                                }}
                                            />
                                        </div>
                                        <div style={{ padding: '16px', position: "relative", bottom: "125px", backgroundColor: "white"}}>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <SamaggiTypography bold large>{speaker.name}</SamaggiTypography>
                                                </Grid>
                                                <Grid item>
                                                    <SamaggiTypography><SamaggiTypography inline bold>Seminar: </SamaggiTypography>{speaker.time}</SamaggiTypography>
                                                </Grid>
                                                <Grid item>
                                                    <SamaggiTypography><SamaggiTypography inline bold>Networking and Discussions: </SamaggiTypography>{speaker.time2}</SamaggiTypography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </SACCPaper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                        <Grid item marginX={8}>
                            <SamaggiTypography bold xlarge colour={Colours.samaggiOrange}>Participating Companies</SamaggiTypography>
                        </Grid>
                        <Grid item container spacing={5} flexWrap="wrap" marginX={8} maxWidth={"100%"}>
                            {sponsorInfo.map((item) => (
                                <Grid item xs={4} md={2}>
                                    <SACCPaper className={classes.border}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <img width={"100%"} src={item.logo} alt={item.name} style={{objectFit: "contain", aspectRatio: "1"}}/>
                                            </Grid>
                                            <Grid item>
                                                <SamaggiTypography bold>{item.name}</SamaggiTypography>
                                            </Grid>
                                        </Grid>
                                    </SACCPaper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </SACCContent>
                <SACCFooter/>
            </Grid>
        </ThemeProvider>
    );
};

export default CareersFair;
