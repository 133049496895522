import Cookies from "universal-cookie";

export function postRequest(endpoint, body) {
    return fetch(endpoint, {
        method: "post",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
    })
}

export const baseURL = "https://ass4n0ckmh.execute-api.eu-west-2.amazonaws.com/Prod/"

export function UUIDv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function handleLogout() {
    const cookies = new Cookies();
    cookies.set("tokenID", "", {path:"/"});
    cookies.set("userID", "", {path:"/"});
    cookies.set("secret", "", {path:"/"});

    window.location = "/cfas/hub/sign-in";
}

export function checkToken(returnPath, userID, tokenID, secret, isCompany, ifValid) {
    let path = "/cfas/hub/home"
    if (returnPath !== undefined) {
        path = returnPath
    }

    if (userID === undefined || tokenID === undefined || secret === undefined || userID === "" || tokenID === "" || secret === "") {
        if (ifValid) {
            return;
        }
        if (isCompany) {
            window.location = "/cfas/company/sign-in?ret=" + encodeURI(path);
            return
        } else {
            window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
            return
        }
    }

    const type = isCompany ? "COMP" : "USER";

    postRequest(baseURL + "check-token", {
        "userID": userID,
        "tokenID": tokenID,
        "secret": secret,
        "type": type
    }).then(async (res) => {
        const data = await res.json()
        console.log(res)
        console.log(data)
        if (res.status === 200) {
            if (data.data["valid"] === undefined || data.data["valid"] === false) {
                if (isCompany) {
                    window.location = "/cfas/company/sign-in?ret=" + encodeURI(path);
                } else if (!ifValid) {
                    window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
                }
            } else if (data.data["valid"] === true) {
                if (ifValid) {
                    window.location = ifValid;
                }
            }
        } else {
            alert(data.data["reason"]);
        }
    }).catch((error) => {
        console.warn(error);
        if (!ifValid) {
            alert("An unexpected problem occurred. Please sign in again.");
            window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
        }
    })
}
