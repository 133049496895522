import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import theme from "../../theme";

const SACCPaper = styled(({ elevation = 6, children, ...rest }) => (
  <Paper elevation={elevation} {...rest}>
    {children}
  </Paper>
))({
  padding: theme.spacing(5),
  borderRadius: `${theme.shape.borderRadius * 5}px ! important`,
});
export default SACCPaper;
