import React, {useContext, useState, useEffect} from 'react'
import Cookies from 'universal-cookie';

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const baseURL = "https://ass4n0ckmh.execute-api.eu-west-2.amazonaws.com/Prod/";
  const signUpURL = baseURL +  "register";
  const verifyEmailURL = baseURL + "verify-email";
  const loginURL = baseURL + "sign-in";
  const listRolesURL = baseURL + "list-roles";
  const getRoleURL = baseURL + "get-role";
  const companyLoginURL = baseURL + "company-sign-in";
  const companyViewApplicationURL = baseURL + "company-view-application";
  const companyInfoURL = baseURL + "get-company";
  const companyApplicationsURL = baseURL + "company-applications";
  const forgotPasswordURL = baseURL + "forgot-password"
  const resetPasswordURL = baseURL + "reset-password"
  const setProfileURL = baseURL + "set-profile";
  const getProfileURL = baseURL + "get-profile";
  const listApplicationURL = baseURL + "list-applications";
  const submitApplicationURL = baseURL + "submit-application";
  const eligiblePosURL = baseURL + "check-eligible-positions";
  const updateStatusURL = baseURL + "update-status";
  const updateMessageURL = baseURL + "update-message";
  const saveNoteURL = baseURL + "save-note";
  const viewApplicationURL = baseURL + "view-application";
  const unsubscribeURL = baseURL + "unsubscribe";
  const registerEventURL = baseURL + "event-register";
  const checkAttendingURL = baseURL + "check-attending";
  const checkProfileURL = baseURL + "profile-exists";
  const companyConnectRegisterURL = baseURL + "register-job-search";
  const revokeCompanyConnectURL = baseURL + "revoke-job-search";
  const getTicketURL = baseURL + "get-ticket";
  const companyViewQRURL = baseURL + "company-view-qr";
  const acceptInterviewURL = baseURL + "accept-interview";

  function signUp(email,user,password) {

    const data = {
      username : user,
      email: email,
      password: password,
    };

    return fetch(signUpURL, {
        method: "post",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    })
  }

  function verifyEmail(userId, key) {

    const data ={
      userID: userId,
      emailKey: key
    } 

    return fetch(verifyEmailURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function login(user, password, secret) {
    const data ={
      username: user,
      password : password,
      secret : secret
    } 

    return fetch(loginURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function listRoles(filters, eligible) {
    const token = localStorage.getItem("token");

    const data ={
      token: token,
      filters : filters,
      onlyEligible : eligible
    } 

    return fetch(listRolesURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function getRole(roleId) {
    const data ={
      roleID : roleId
    } 

    return fetch(getRoleURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function companyLogin(user,password,secret) {
    const data ={
      username: user,
      password : password,
      secret : secret
    } 
  

    return fetch(companyLoginURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }
  
  function companyViewApplication(applicationId, tokenId, secret, companyId) {
    const data ={
      applicationID: applicationId,
      tokenID: tokenId,
      secret: secret,
      companyID: companyId
    } 

    return fetch(companyViewApplicationURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function companyInfo(companyId) {
    const data ={
      companyID : companyId
    } 

    return fetch(companyInfoURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function companyApplications(companyId, tokenId, secret, filters) {
    const data = {
      "companyID": companyId,
      "tokenID": tokenId,
      "secret": secret,
      "filters": filters
    }

    return fetch(companyApplicationsURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }


  function forgotPassword(email) {
    const data = {
      username : email
    }
    return fetch(forgotPasswordURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function resetPassword(user, password) {
    const data = {
      data: user,
      "new-password": password
    }

    return fetch(resetPasswordURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }


  function listApplication() {
    const token = localStorage.getItem("token");

    const data = {
      token: token
    }

    return fetch(listApplicationURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function setProfile(formData) {
    const token = localStorage.getItem("token");

    const data = {
      token : token,
      formData : formData
    }

    return fetch(setProfileURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function getProfile(modeLoad = false) {
    const token = localStorage.getItem("token");

    const data = {
      token : token,
      modeLoad : modeLoad
    }
    
    return fetch(getProfileURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function submitApplication(positionId, text, coverLetterLink) {
    const token = localStorage.getItem("token");

    const data = {
      token: token,
      positionID: positionId,
      additionalText: text,
      coverFile: coverLetterLink
    }

    return fetch(submitApplicationURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function checkEligiblePos(companyId) {
    const token = localStorage.getItem("token");

    const data = {
      "token": token,
      "companyID": companyId
    }

    return fetch(eligiblePosURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  } 

  function logout() {
    const cookies = new Cookies();
    cookies.set("tokenID", "", {path:"/"});
    cookies.set("userID", "", {path:"/"});
    cookies.set("secret", "", {path:"/"});

    window.location = "/cfas/hub/sign-in";
    return setCurrentUser(null)
  }

  function updateStatus(companyID, tokenID, secret, applicationID, status) {
    const data = {
      "companyID": companyID,
      "tokenID": tokenID,
      "secret": secret,
      "applicationID": applicationID,
      "status": status
    }

    return fetch(updateStatusURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function saveNote(companyID, tokenID, secret, applicationID, note) {
    const data = {
      "companyID": companyID,
      "tokenID": tokenID,
      "secret": secret,
      "applicationID": applicationID,
      "note": note
    }

    return fetch(saveNoteURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function updateMessage(companyID, tokenID, secret, applicationID, message) {
    const data = {
      "companyID": companyID,
      "tokenID": tokenID,
      "secret": secret,
      "applicationID": applicationID,
      "message": message
    }

    return fetch(updateMessageURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function viewApplication(applicationID) {
    const token = localStorage.getItem("token");

    const data = {
      "token": token,
      "applicationID": applicationID
    }

    return fetch(viewApplicationURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function unsubscribeRequest(userID) {
    const data = {
      "userID": userID
    }

    return fetch(unsubscribeURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function registerEvent(formData) {
    const token = localStorage.getItem("token");

    const data = {
      formData: formData,
      token: token
    }

    return fetch(registerEventURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function acceptInterview(applicationID) {
    const token = localStorage.getItem("token");

    const data = {
      applicationID: applicationID,
      token: token
    }

    return fetch(acceptInterviewURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function checkAttending() {
    const token = localStorage.getItem("token");

    const data = {
      token : token
    }

    return fetch(checkAttendingURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function checkProfileAPI() {
    const token = localStorage.getItem("token");

    const data = {
      token : token
    }

    return fetch(checkProfileURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  
  function checkToken(returnPath, userID, tokenID, secret, isCompany, ifValid) {
    let path = "/cfas/hub/home"
    if (returnPath !== undefined) {
        path = returnPath
    }

    if (userID === undefined || tokenID === undefined || secret === undefined || userID === "" || tokenID === "" || secret === "") {
        if (ifValid) {
            return;
        }
        if (isCompany) {
            window.location = "/cfas/company/sign-in?ret=" + encodeURI(path);
            return
        } else {
            window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
            return
        }
    }

    const type = isCompany ? "COMP" : "USER";

    const data = {
      "userID": userID,
      "tokenID": tokenID,
      "secret": secret,
      "type": type
    }

    fetch(baseURL + "check-token", {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    }).then(async (res) => {
      const data = await res.json()
      console.log(res)
      console.log(data)
      if (res.status === 200) {
        if (data.data["valid"] === undefined || data.data["valid"] === false) {
          if (isCompany) {
            window.location = "/cfas/company/sign-in?ret=" + encodeURI(path);
          } else if (!ifValid) {
            window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
          }
        } else if (data.data["valid"] === true) {
          if (ifValid) {
            window.location = ifValid;
          }
        }
      } else {
        alert(data.data["reason"]);
      }
    }).catch((error) => {
      console.warn(error);
      if (!ifValid) {
        alert("An unexpected problem occurred. Please sign in again.");
        window.location = "/cfas/hub/sign-in?ret=" + encodeURI(path);
      }
    })
  }

  function registerCompanyConnect(userID, tokenID, secret, type) {
    const data = {
      "userID": userID,
      "tokenID": tokenID,
      "secret": secret,
      "type": type
    }

    return fetch(companyConnectRegisterURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function revokeCompanyConnect(userID, tokenID, secret) {
    const data = {
      "userID": userID,
      "tokenID": tokenID,
      "secret": secret
    }

    return fetch(revokeCompanyConnectURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function getTicket() {
    const token = localStorage.getItem("token");

    const data = {
      token : token
    }

    return fetch(getTicketURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }

  function companyViewQR(userID, companyID, tokenID, secret) {
    const data = {
      "companyID": companyID,
      "tokenID": tokenID,
      "secret": secret,
      "userID": userID
    }

    return fetch(companyViewQRURL, {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    })
  }


  useEffect(() => {
    setLoading(false)
  }, [])
  
  const value = {
      currentUser,
      signUp,
      verifyEmail,
      login,
      listRoles,
      getRole,
      companyViewApplication,
      companyLogin,
      companyInfo,
      companyApplications,
      forgotPassword,
      resetPassword,
      listApplication,
      setProfile,
      getProfile,
      submitApplication,
      checkEligiblePos,
      logout,
      checkToken,
      updateStatus,
      saveNote,
      updateMessage,
      viewApplication,
      unsubscribeRequest,
      registerEvent,
      checkAttending,
      checkProfileAPI,
      registerCompanyConnect,
      revokeCompanyConnect,
      getTicket,
      companyViewQR,
      acceptInterview
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}