import {Grid} from "@mui/material";

const CFASWrapper = (props) => {
    return (
        <Grid container direction={"row"} justifyContent={"space-evenly"}>
            <Grid item xs={12} xl={8} lg={10}>
                {props.children}
            </Grid>
        </Grid>
    )
}

export default CFASWrapper;