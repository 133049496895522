import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import SACCTextButton from "../../common/SACC/SACCTextButton";
import Box from "@mui/material/Box";
import SACCIcon from "./SACCIcon";
import SACCPaper from "./SACCPaper";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const SACCWorkshop = ({ item }) => {
  const classes = useStyles();
  const currDate = new Date();
  return (
    <Grid item>
      <SACCPaper>
        <Grid container direction="column" rowSpacing={3}>
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {item.name}
            </Typography>
            <Typography>{item.type}</Typography>
          </Grid>
          {item.type === "On-site Event" ? (
            <Grid item container>
              <Grid item sx={{ marginRight: theme.spacing(2) }}>
                <SACCIcon></SACCIcon>
              </Grid>
              <Grid item>
                <Grid container sx={{ height: "100%" }} alignItems="center">
                  <Grid item>
                    <Typography>{item.location}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item sx={{ marginBottom: theme.spacing(2) }}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                border: "1px solid black",
              }}
            >
              <Typography>{item.timetable}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              {item.date < currDate
                ? "Missed the event, click here"
                : "Click here to join Zoom"}
            </Typography>
            <SACCTextButton>
              {item.date < currDate ? "Zoom" : "Join Here"}
            </SACCTextButton>
          </Grid>
        </Grid>
      </SACCPaper>
    </Grid>
  );
};

export default SACCWorkshop;
