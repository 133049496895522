import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import theme from "../theme";

const NavbarButton = styled(({ children, ...rest }) => (
  <Button size="large" fullWidth disableRipple disableFocusRipple {...rest}>
    <Typography variant="subtitle1">{children}</Typography>
  </Button>
))({
  color: "#ffffff",
  textTransform: "none",
  padding: theme.spacing(2, 5),
  "&:hover": {
    textDecoration: "underline",
    backgroundColor: "transparent",
    textDecorationColor: "#ffffff",
    textUnderlineOffset: theme.spacing(1),
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
});
export default NavbarButton;
