import theme from "../../theme";
import Grid from "@mui/material/Grid";
import SACCPaper from "./SACCPaper";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SACCTextButton from "./SACCTextButton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    fontSize: "30px",
    color: "white",
  },
  subheader: {
    fontWeight: `${theme.typography.fontWeightLight} !important`,
    fontSize: "20px",
    color: "white",
  },
  caseCompButton: {
    color: "white",
    borderColor: "white ! important",
  },
}));

const SACCCaseComp = () => {
  const classes = useStyles();
  return (
    <SACCPaper
      sx={{
        background: "rgba(255, 255, 255, 0.3)",
        padding: theme.spacing(8, 8),
      }}
    >
      <Grid container direction="column" rowSpacing={5}>
        <Grid item>
          <Typography variant="p" className={classes.header}>
            Case Competition Deadline Extend
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="p" className={classes.subheader}>
            The application deadline has now been extended. Application sign-up
            will now be close on the 4th November 2022 (18:00 UK time)
          </Typography>
        </Grid>
        <Grid item container columnSpacing={5}>
          <Grid item>
            <SACCTextButton
              variant="outlined"
              className={classes.caseCompButton}
              endIcon={<ArrowRightAltIcon />}
            >
              Find Out
            </SACCTextButton>
          </Grid>
          <Grid item>
            <SACCTextButton
              variant="outlined"
              className={classes.caseCompButton}
            >
              Apply Now
            </SACCTextButton>
          </Grid>
        </Grid>
      </Grid>
    </SACCPaper>
  );
};
export default SACCCaseComp;
