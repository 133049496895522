import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useState} from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {useAuth} from "../../../contexts/AuthContext";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";


const useStyles = makeStyles(() => basicStyle);


const HubHome = (props) => {
    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);
    const [cvLink, setCvLink] = useState("");
    const [profileData, setProfileData] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const {getProfile, checkAttending} = useAuth();
    const [userAttending, setUserAttending] = useState(false);
    const [attendingLoading, setAttendingLoading] = useState(true);

    function handleCVButton() {
        if (cvLink !== "") {
            console.log(cvLink);
            window.open(cvLink, "_blank");
        }
    }

    function getData() {

        // checkToken("/cfas/hub/home", userID, tokenID, secret);

        getProfile().then(async (res) => {
            const data = await res.json()
            if (res.status === 200) {
                setCvLink(data.data["cvLink"]);
                setProfileData(data.data);
                console.log(data)
                props.setShowCover(false);
                setLoaded(true);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                window.location = "/cfas/hub/register";
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })

        setAttendingLoading(true);

        checkAttending().then(async (res) => {
            setAttendingLoading(false);
            const data = await res.json()
            if (res.status === 200) {
                setUserAttending(data.data["attending"]);
            } else {
                console.warn(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    function goToTrack() {
        window.location = "/cfas/track/home";
    }

    function goToApply() {
        window.location = "/cfas/apply/home";
    }

    function goToEvent() {
        // window.location = "/cfas/event/register";
    }

    useEffect(() => {
        if (firstLoad) {
            getData();
            props.setShowCover(true);
            setFirstLoad(false);
        }
    })

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={6}>
                <CFASHeader secondaryText={"Edit Profile"} secondaryURL={"/cfas/hub/register"}>
                    Samaggi CFAS
                </CFASHeader>
                <Grid item>
                    <SACCPaper>
                        <Typography gutterBottom variant="h6" className={classes.title}>
                            Personal Information
                        </Typography>
                        { loaded && (
                            <Grid container direction={largeScreen?"row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={3}>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Full Name
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["fullName"]} ({profileData["nickname"]})
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Date of Birth
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["birthdate"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["email"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Line ID
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["lineID"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Phone Number
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            +{profileData["phoneNumber"].replace("//", " ")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Gender
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["gender"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            )
                        }
                        <Typography gutterBottom variant="h6" className={classes.title} sx={{marginTop: 10}}>
                            Education Information
                        </Typography>
                        <Grid container direction="row" spacing={4}>
                            <Grid container item direction={largeScreen?"row":"column"} justifyContent={"space-between"} spacing={3} columnSpacing={4}>
                                <Grid container item xs={6} direction="column">
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            University
                                        </Typography>
                                    </Grid>
                                    { loaded && (<Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["university"].toString().replace("Other//", "Other – ")}
                                        </Typography>
                                    </Grid>)}
                                </Grid>
                                <Grid container item xs={6} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Course Area
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["courseArea"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction={largeScreen?"row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                                <Grid container item xs={4} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Course Name
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["courseName"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Year of Study
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["year"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={4} direction={"column"}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                            Course Level
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            {profileData["level"]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"} sx={{marginTop: 10}}>
                            <Grid item container direction={"column"} xs={9} alignItems={"space-evenly"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        CV File
                                    </Typography>
                                </Grid>
                            </Grid>
                            {cvLink !== "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                <Button className={classes.logoffButton} onClick={handleCVButton}>Download a Copy</Button>
                            </Grid>}
                            {cvLink === "" && <Grid item xs={12} md={3} marginTop={largeScreen ? 0 : 3}>
                                <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/hub/register"}>Upload CV</Button>
                            </Grid>}
                        </Grid>
                    </SACCPaper>
                </Grid>
                {!attendingLoading && !userAttending && <Grid item>
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        In-Person Event
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0}}>
                                        Register for the In-Person Careers Fair Event
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                <Grid item height={"fit-content"}>
                                    <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/event/register"}>CFAS Event</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>}
                {!attendingLoading && userAttending && <Grid item>
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"} columnSpacing={3}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Registered for In-Person Event
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0}}>
                                        You already have registered for the in-person Careers Fair event. You can view your ticket and profile share code at CFAS Event.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                <Grid item height={"fit-content"}>
                                    <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/event/home"}>CFAS Event</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>}
                <Grid item height={"fit-content !important"}>
                    <SACCPaper sx={{overflow: "none"}}>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Apply
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text} sx={{marginBottom: 0, width: "100%"}}>
                                        {cvLink === "" ? "You must upload a CV to access Apply." : "Apply to open positions and internships."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3}>
                                {cvLink !== "" && <Grid item height={"fit-content"} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                    <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={goToApply}>CFAS Apply</Button>
                                </Grid>}
                                {cvLink === "" && <Grid item height={"fit-content"} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                    <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={() => window.location = "/cfas/hub/register"}>Upload CV</Button>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
                <Grid item >
                    <SACCPaper>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} xs={12} md={9}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleSmallMargin}>
                                        Track
                                    </Typography>
                                </Grid>
                                <Grid item sx={{flexWrap: "no-wrap", overflowWrap: "no-wrap"}}>
                                    <Typography variant="h6" className={classes.text}>
                                        {cvLink !== "" ? "Track and view applications you've made." : "Make an application to access Track."}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"column"} justifyContent={"space-evenly"} xs={12} md={3} marginTop={largeScreen ? 0 : 3} marginBottom={largeScreen ? 0 : 3}>
                                {cvLink !== "" && <Grid item height={"fit-content"}>
                                    <Button className={classes.logoffButton} endIcon={<ArrowRightAltIcon/>} onClick={goToTrack}>CFAS Track</Button>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </SACCPaper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default HubHome;