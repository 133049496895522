import { Typography, Grid, ThemeProvider} from "@mui/material";
import theme from "../../../theme";
import SACCPaper from "../../../common/SACC/SACCPaper";
import {useEffect, useState} from "react";
import { useAuth } from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";

const Unsubscribe = () => {
    const [status, setStatus] = useState("Unsubscribing...");
    const [firstLoad, setFirstLoad] = useState(true);
    const {unsubscribeRequest} = useAuth();

    useEffect(() => {
        if (firstLoad) {
            const urlParams = new URLSearchParams(window.location.search);
            const userID = urlParams.get('userID');
            if (userID === null) {
                alert("Invalid URL");
                window.location = "/cfas/hub/sign-in";
            }

            unsubscribe(userID);
            setFirstLoad(false);
        }
    })

    function unsubscribe(userID) {
        unsubscribeRequest(userID).then(async (res) => {
            const data = await res.json()

            if (res.status === 200) {
                if (data.data["success"] === true) {
                    if (data.data["alreadyUnsubscribed"]) {
                        alert("You have already unsubscribed from company emails. You will still receive updates about your applications.");
                    } else {
                        setStatus("Successfully unsubscribed from company emails. You will still receive updates on your application.");
                    }
                } else {
                    alert("Unable to unsubscribe. Please try again later.");
                }
                setTimeout(() => {
                    window.location = "/cfas/hub/sign-in";
                }, 1500)
            } else {
                alert(data.data["reason"] + " Please try again later.");
                window.location = "/cfas/hub/sign-in";
            }
        }).catch((error) => {
            alert("Unable to unsubscribe. Please try again later.");
            console.log(error)
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                padding="100px"
                spacing={4}
            >
                <CFASHeader hideLogOut>
                    Samaggi CFAS
                </CFASHeader>
                <Grid item container spacing={5} alignContent={"center"} justifyContent={"center"}>
                    <SACCPaper sx={{width: "50%", marginTop: 20}}>
                        <Typography variant={"h4"} sx={{textAlign: "center"}}>{status}</Typography>
                    </SACCPaper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Unsubscribe;
