import {Button, Grid, Typography} from "@mui/material";
import erawan_orange from "../../../assets/erawan_elephant_orange.svg";
import * as PropTypes from "prop-types";
import {signOut} from "../../../TSsupport"
import {makeStyles} from "@mui/styles";
import { ReactElement } from "react";

const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: 700,
        fontSize: "48px",
    },
    logo: {
        width: "60px",
        height: "100px",
    },
    button: {
        background: "#FB8A3E",
        color: "white",
        width: "100%",
        padding: "15px",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        "&:hover": {
            background: "#FB8A3E",
        },
    }
}));


export function CFASHeader(props: { children: ReactElement | string; secondaryText: string; secondaryURL: string; hideLogOut?: boolean;}) {
  const classes = useStyles();

  return <Grid container item justifyContent="space-between" rowSpacing={3}>
      <Grid item>
          <Grid container alignItems="center" spacing={3}>
              <Grid item>
                  <img className={classes.logo} src={erawan_orange.toString()} alt={"Logo"}/>
              </Grid>
              <Grid item>
                  <Typography className={classes.applicationTitle} variant="h5">
                    {props.children}
                  </Typography>
              </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12} md={4} container alignItems="center" justifyContent={"right"} spacing={4}>
          {props.secondaryText !== undefined && (<Grid item xs={12} lg={6}>
              <Button className={classes.button} onClick={() => {window.location.href = props.secondaryURL}}>{props.secondaryText}</Button>
          </Grid>)}
          {props.hideLogOut === undefined && <Grid item xs={12} lg={6}>
              <Button className={classes.button} onClick={() => signOut()}>Log Out</Button>
          </Grid>}
      </Grid>
  </Grid>;
}

CFASHeader.propTypes = {
    secondaryURL: PropTypes.string,
    secondaryText: PropTypes.string
};
