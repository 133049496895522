import Grid from "@mui/material/Grid";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import SACCTextButton from "../../common/SACC/SACCTextButton";
import { ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SACCCommitment from "../../common/SACC/SACCCommitment";
import Box from "@mui/material/Box";
import SACCPaper from "../../common/SACC/SACCPaper";
import SACCContent from "../../common/SACC/SACCContent";
import SACCDivider from "../../common/SACC/SACCDivider";
import SACCFooter from "../../common/SACC/SACCFooter";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const divider = {
  marginTop: theme.spacing(6),
  border: "1px solid black",
  width: "20%",
};

const commitments = [
  {
    commitment: 1,
    name: "Environmental",
    image: "Image",
  },
  {
    commitment: 2,
    name: "Social",
    image: "Image",
  },
  {
    commitment: 3,
    name: "Financial",
    image: "Image",
  },
];

const About = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <SACCPageHeader />
      </Grid>
      <SACCContent padded>
        <Grid item>
          <Typography
            align="center"
            gutterBottom
            variant="h6"
            className={classes.title}
          >
            Introduction
          </Typography>
          <Typography align="center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>
        <SACCDivider />
        <Grid item container direction="column" rowSpacing={5}>
          <Grid item>
            <Typography gutterBottom variant="h6" className={classes.title}>
              Responsibility
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </Typography>
          </Grid>
          <Grid item container columnSpacing={5}>
            {commitments.map((item) => (
              <SACCCommitment item={item} />
            ))}
          </Grid>
          <Grid item>
            <SACCTextButton
              onClick={() => {
                navigate("/responsibility");
              }}
              endIcon={<ArrowRightAlt />}
            >
              Find Out
            </SACCTextButton>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Contact Us
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <SACCPaper>
                <Box
                  sx={{
                    width: "100%",
                    height: "500px",
                    border: "1px solid black",
                  }}
                >
                  <Typography>Contact Us Details</Typography>
                </Box>
              </SACCPaper>
            </Grid>
          </Grid>
        </Grid>
      </SACCContent>
      <SACCFooter/>
    </Grid>
  );
};

export default About;
