import Grid from "@mui/material/Grid";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import SACCDivider from "../../common/SACC/SACCDivider";
import SACCWorkshop from "../../common/SACC/SACCWorkshop";
import SACCJudges from "../../common/SACC/SACCJudges";
import SACCContent from "../../common/SACC/SACCContent";
import SACCFooter from "../../common/SACC/SACCFooter";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const workshopEvents = [
  {
    name: "Workshop 1",
    type: "Virtual Event",
    date: new Date(2025, 3),
    timetable: "Timetable",
  },
  {
    name: "Workshop 2",
    type: "On-site Event",
    location: "Location",
    date: new Date(2002, 2),
    timetable: "Timetable",
  },
];

const placeholder =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing elit.";
const judges = [
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: placeholder,
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: placeholder,
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: placeholder,
  },
];

const Workshops = () => {
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <SACCPageHeader />
      </Grid>
      <SACCContent padded>
        <Grid item>
          <Typography
            align="center"
            gutterBottom
            variant="h6"
            className={classes.title}
          >
            Introduction
          </Typography>
          <Typography align="center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>
        <SACCDivider />
        <Grid item container direction="column" rowSpacing={10}>
          {workshopEvents.map((item) => (
            <SACCWorkshop item={item} />
          ))}
        </Grid>

        <Grid item>
          <Typography variant="h6" className={classes.title} gutterBottom>
            Judges
          </Typography>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Grid>
        <Grid item container spacing={5}>
          {judges.map((item) => (
            <SACCJudges item={item} />
          ))}
        </Grid>
      </SACCContent>
      <SACCFooter/>
    </Grid>
  );
};

export default Workshops;
