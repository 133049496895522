import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import erawan_orange from "../../../assets/erawan_elephant_orange.svg";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASProfileEducationSection} from "../../../common/SACC/CareersFairContext/CFASProfileEducationSection";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";

const useStyles = makeStyles(() => basicStyle);

const Profile = () => {

    const {companyViewQR, checkToken} = useAuth();
    const classes = useStyles();

    const cookies = new Cookies();

    const [loaded, setLoaded] = useState(false);
    const [cvLink, setCvLink] = useState("");
    const [profileData, setProfileData] = useState({});
    const [userData, setUserData] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);

    function handleCVButton() {
        if (cvLink !== "") {
            console.log(cvLink);
            window.open(cvLink, "_blank");
        }
    }

    function getData(userID) {
        const companyID = cookies.get("companyID");
        const tokenID = cookies.get("cTokenID");
        const secret = cookies.get("cSecret");

        companyViewQR(userID, companyID, tokenID, secret).then(async (res) => {
            const data = await res.json()
            if (res.status === 200) {
                setCvLink(data.data["cvLink"]);

                if (Object.keys(data.data).includes("user_data")) {
                    setUserData(data.data["user_data"]);
                }
                if (Object.keys(data.data).includes("profile_data")) {
                    setProfileData(data.data["profile_data"]);
                    setCvLink(data.data["profile_data"]["cvLink"]);
                }

                setLoaded(true);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    useEffect(() => {
        if (firstLoad) {
            const urlParams = new URLSearchParams(window.location.search);
            const userID = urlParams.get('userID');

            const companyID = cookies.get("companyID");
            const tokenID = cookies.get("cTokenID");
            const secret = cookies.get("cSecret");

            checkToken("/cfas/company/view-qr?userID=" + userID, companyID, tokenID, secret, true);

            getData(userID);
            setFirstLoad(false);
        }
    })

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={theme}>
            {loaded && <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={1}>
                <CFASHeader classes={classes} secondaryURL={"/cfas/company/home"} secondaryText={"Back to List"}>
                    CFAS Apply
                </CFASHeader>
                <Grid item>
                    {loaded && (
                    <SACCPaper sx={{marginTop: 5, paddingBottom: 10, marginBottom: 4}}>
                        <Typography gutterBottom variant="h6" className={classes.title}>
                            Personal Information
                        </Typography>
                        <Grid container direction={largeScreen ? "row" : "column"} justifyContent={"space-between"}
                              rowSpacing={2} columnSpacing={3}>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Full Name
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["fullName"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Nickname
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["nickname"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {userData["email"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Line ID
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["lineID"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Phone Number
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        +{profileData["phoneNumber"].replace("//", " ")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Gender
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["gender"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography gutterBottom variant="h6" className={classes.title} sx={{marginTop: 10}}>
                            Education Information
                        </Typography>
                        <CFASProfileEducationSection largeScreen={largeScreen} classes={classes} profileData={profileData}/>
                    </SACCPaper>
                    )}
                </Grid>
                <Grid item container direction={"row"} columnSpacing={6} rowSpacing={6}>
                    <Grid item xs={12}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction={"column"} rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Candidate's CV
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download and view a copy of the candidate's CV.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleCVButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                </Grid>
            </Grid>}
        </ThemeProvider>
    );
};

export default Profile;