import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const SACCAwareness = ({ item }) => {
  const classes = useStyles();
  return (
    <Grid item container columnSpacing={4}>
      <Grid item xs={4}>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            border: "1px solid black",
          }}
        >
          <Typography>{item.image}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={8} direction="column" justifyContent="center">
        <Grid item>
          <Typography
            gutterBottom
            variant="h6"
            className={classes.sectionHeader}
          >
            {item.title}
          </Typography>
          <Typography>{item.content}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SACCAwareness;
