import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import theme from "../../theme";

const SACCDivider = styled(({ children, ...rest }) => <Divider {...rest} />)({
  marginTop: theme.spacing(6),
  border: "1px solid black",
  width: "20%",
});

export default SACCDivider;
