import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import SACCPaper from "../SACCPaper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import SACCTextButton from "../SACCTextButton";
import {SamaggiTypography} from "../../SamaggiTypography";


const useStyles = makeStyles(() => ({
  headerContainer: {
      minHeight: "30vh",
      padding: theme.spacing(5, 10),
  },
  englishTitle: {
      fontWeight: `600 !important`,
      marginTop: "0",
      fontFamily: "Montserrat"
  },
  thaiTitle: {
    fontWeight: `600 !important`,
    marginTop: "0",
    fontFamily: "Montserrat"
  },
  subheader: {
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    fontSize: "35px",
    fontFamily: "Montserrat",
  }
}));


const title_en = "Learning From the Past, Addressing the Present, and Embracing the Future"
const title_th = "ความหวังที่ไม่กล้าหวัง"
const SACCCaseCompPaper = () => {
  const classes = useStyles()
  return (
    <Grid item>
      <SACCPaper sx={{padding : "50px", background: "#FFF1DC"}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item flexDirection="column" spacing={4}>
              <Grid item container flexDirection="column" spacing={1}>

                  <Grid item>
                      <SamaggiTypography bold xlarge center>SACC 2024 Theme</SamaggiTypography>
                  </Grid>
                  <Grid item>
                      <SamaggiTypography center>{title_en}</SamaggiTypography>
                  </Grid>
                  <Grid item sx={{marginTop: "8px"}}>
                      <SamaggiTypography center>{title_th}</SamaggiTypography>
                  </Grid>
              </Grid>
          </Grid>
        </Grid>
      </SACCPaper>
    </Grid>
  )
}
export default SACCCaseCompPaper;
