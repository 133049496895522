import {Component} from "react";
import {Grid, Typography} from "@mui/material";
import * as PropTypes from "prop-types";

export class CFASProfileEducationSection extends Component {
    render() {
        return <Grid container direction={this.props.largeScreen ? "row" : "column"} justifyContent={"space-between"}
                     rowSpacing={3} columnSpacing={4}>
            <Grid container item xs={6} direction="column">
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.subtitle}>
                        University
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.text}>
                        {this.props.profileData["university"].toString().replace("Other//", "Other – ")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={6} direction={"column"}>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.subtitle}>
                        Course Area
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.text}>
                        {this.props.profileData["courseArea"]}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={4} direction={"column"}>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.subtitle}>
                        Course Name
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.text}>
                        {this.props.profileData["courseName"]}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={4} direction={"column"}>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.subtitle}>
                        Year of Study
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.text}>
                        {this.props.profileData["year"]}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={4} direction={"column"}>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.subtitle}>
                        Course Level
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="h6" className={this.props.classes.text}>
                        {this.props.profileData["level"]}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>;
    }
}

CFASProfileEducationSection.propTypes = {
    classes: PropTypes.any,
    profileData: PropTypes.shape({}),
    largeScreen: PropTypes.bool
};
