import theme from "../../../theme"
import { Grid, Button, ThemeProvider } from "@mui/material"
import InputField from "../../../common/SACC/CareersFairContext/InputField";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown"
import Label from "../../../common/SACC/CareersFairContext/Label"
import {UUIDv4} from "../../../support";
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import AWS from "aws-sdk"
import {courseAreaList, courseLevelList} from "../../../assets/options";
import SACCPaper from "../../../common/SACC/SACCPaper";
import Upload from "../../../common/SACC/CareersFairContext/Upload";
import {makeStyles} from "@mui/styles";
import { useAuth } from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";

const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: "700",
        fontSize: "32px",
    },
    logo: {
        width: "50px",
    },
    logoffButton: {
        background: "#FB8A3E !important",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px !important",
        "&:hover": {
            background: "#FB8A3E",
        },
        marginTop: "auto",
        marginBottom: "auto"
    },
    submitButton: {
        background: "#FB8A3E",
        color: "white",
        width: "50%",
        padding: "10px 0",
        fontSize: "24px",
        borderRadius: "15px"
    }
}));

const AbstractSubmit = () => {

    const phoneMap = {
        'United Kingdom (+44)': '44',
        'Thailand (+66)': '66',
        'Åland Islands (+358)': '358',
        'Afghanistan (+93)': '93',
        'Albania (+355)': '355',
        'Algeria (+213)': '213',
        'American Samoa (+1-684)': '1-684',
        'Andorra (+376)': '376',
        'Angola (+244)': '244',
        'Anguilla (+1-264)': '1-264',
        'Antarctica (+672)': '672',
        'Antigua & Barbuda (+1-268)': '1-268',
        'Argentina (+54)': '54',
        'Armenia (+374)': '374',
        'Aruba (+297)': '297',
        'Australia (+61)': '61',
        'Austria (+43)': '43',
        'Azerbaijan (+994)': '994',
        'Bahamas (+1-242)': '1-242',
        'Bahrain (+973)': '973',
        'Bangladesh (+880)': '880',
        'Barbados (+1-246)': '1-246',
        'Belarus (+375)': '375',
        'Belgium (+32)': '32',
        'Belize (+501)': '501',
        'Benin (+229)': '229',
        'Bermuda (+1-441)': '1-441',
        'Bhutan (+975)': '975',
        'Bolivia (+591)': '591',
        'Bosnia (+387)': '387',
        'Botswana (+267)': '267',
        'Bouvet Island (+47)': '47',
        'Brazil (+55)': '55',
        'British Indian Ocean Territory (+246)': '246',
        'British Virgin Islands (+1-284)': '1-284',
        'Brunei (+673)': '673',
        'Bulgaria (+359)': '359',
        'Burkina Faso (+226)': '226',
        'Burundi (+257)': '257',
        'Cambodia (+855)': '855',
        'Cameroon (+237)': '237',
        'Canada (+1)': '1',
        'Cape Verde (+238)': '238',
        'Caribbean Netherlands (+599)': '599',
        'Cayman Islands (+1-345)': '1-345',
        'Central African Republic (+236)': '236',
        'Chad (+235)': '235',
        'Chile (+56)': '56',
        'China (+86)': '86',
        'Christmas Island (+61)': '61',
        'Cocos (Keeling) Islands (+61)': '61',
        'Colombia (+57)': '57',
        'Comoros (+269)': '269',
        'Congo - Brazzaville (+242)': '242',
        'Congo - Kinshasa (+243)': '243',
        'Cook Islands (+682)': '682',
        'Costa Rica (+506)': '506',
        'Croatia (+385)': '385',
        'Cuba (+53)': '53',
        'Curaçao (+599)': '599',
        'Cyprus (+357)': '357',
        'Czechia (+420)': '420',
        'Côte d’Ivoire (+225)': '225',
        'Denmark (+45)': '45',
        'Djibouti (+253)': '253',
        'Dominica (+1-767)': '1-767',
        'Dominican Republic (+1-809, 1-829,1-849)': '1-809,1-829,1-849',
        'Ecuador (+593)': '593',
        'Egypt (+20)': '20',
        'El Salvador (+503)': '503',
        'Equatorial Guinea (+240)': '240',
        'Eritrea (+291)': '291',
        'Estonia (+372)': '372',
        'Eswatini (+268)': '268',
        'Ethiopia (+251)': '251',
        'Falkland Islands (+500)': '500',
        'Faroe Islands (+298)': '298',
        'Fiji (+679)': '679',
        'Finland (+358)': '358',
        'France (+33)': '33',
        'French Guiana (+594)': '594',
        'French Polynesia (+689)': '689',
        'French Southern Territories (+262)': '262',
        'Gabon (+241)': '241',
        'Gambia (+220)': '220',
        'Georgia (+995)': '995',
        'Germany (+49)': '49',
        'Ghana (+233)': '233',
        'Gibraltar (+350)': '350',
        'Greece (+30)': '30',
        'Greenland (+299)': '299',
        'Grenada (+1-473)': '1-473',
        'Guadeloupe (+590)': '590',
        'Guam (+1-671)': '1-671',
        'Guatemala (+502)': '502',
        'Guernsey (+44)': '44',
        'Guinea (+224)': '224',
        'Guinea-Bissau (+245)': '245',
        'Guyana (+592)': '592',
        'Haiti (+509)': '509',
        'Heard & McDonald Islands (+672)': '672',
        'Honduras (+504)': '504',
        'Hong Kong (+852)': '852',
        'Hungary (+36)': '36',
        'Iceland (+354)': '354',
        'India (+91)': '91',
        'Indonesia (+62)': '62',
        'Iran (+98)': '98',
        'Iraq (+964)': '964',
        'Ireland (+353)': '353',
        'Isle of Man (+44)': '44',
        'Israel (+972)': '972',
        'Italy (+39)': '39',
        'Jamaica (+1-876)': '1-876',
        'Japan (+81)': '81',
        'Jersey (+44)': '44',
        'Jordan (+962)': '962',
        'Kazakhstan (+7)': '7',
        'Kenya (+254)': '254',
        'Kiribati (+686)': '686',
        'Kuwait (+965)': '965',
        'Kyrgyzstan (+996)': '996',
        'Laos (+856)': '856',
        'Latvia (+371)': '371',
        'Lebanon (+961)': '961',
        'Lesotho (+266)': '266',
        'Liberia (+231)': '231',
        'Libya (+218)': '218',
        'Liechtenstein (+423)': '423',
        'Lithuania (+370)': '370',
        'Luxembourg (+352)': '352',
        'Macau (+853)': '853',
        'Madagascar (+261)': '261',
        'Malawi (+265)': '265',
        'Malaysia (+60)': '60',
        'Maldives (+960)': '960',
        'Mali (+223)': '223',
        'Malta (+356)': '356',
        'Marshall Islands (+692)': '692',
        'Martinique (+596)': '596',
        'Mauritania (+222)': '222',
        'Mauritius (+230)': '230',
        'Mayotte (+262)': '262',
        'Mexico (+52)': '52',
        'Micronesia (+691)': '691',
        'Moldova (+373)': '373',
        'Monaco (+377)': '377',
        'Mongolia (+976)': '976',
        'Montenegro (+382)': '382',
        'Montserrat (+1-664)': '1-664',
        'Morocco (+212)': '212',
        'Mozambique (+258)': '258',
        'Myanmar (+95)': '95',
        'Namibia (+264)': '264',
        'Nauru (+674)': '674',
        'Nepal (+977)': '977',
        'Netherlands (+31)': '31',
        'New Caledonia (+687)': '687',
        'New Zealand (+64)': '64',
        'Nicaragua (+505)': '505',
        'Niger (+227)': '227',
        'Nigeria (+234)': '234',
        'Niue (+683)': '683',
        'Norfolk Island (+672)': '672',
        'North Korea (+850)': '850',
        'North Macedonia (+389)': '389',
        'Northern Mariana Islands (+1-670)': '1-670',
        'Norway (+47)': '47',
        'Oman (+968)': '968',
        'Pakistan (+92)': '92',
        'Palau (+680)': '680',
        'Palestine (+970)': '970',
        'Panama (+507)': '507',
        'Papua New Guinea (+675)': '675',
        'Paraguay (+595)': '595',
        'Peru (+51)': '51',
        'Philippines (+63)': '63',
        'Pitcairn Islands (+870)': '870',
        'Poland (+48)': '48',
        'Portugal (+351)': '351',
        'Puerto Rico (+1)': '1',
        'Qatar (+974)': '974',
        'Romania (+40)': '40',
        'Russia (+7)': '7',
        'Rwanda (+250)': '250',
        'Réunion (+262)': '262',
        'Samoa (+685)': '685',
        'San Marino (+378)': '378',
        'Saudi Arabia (+966)': '966',
        'Senegal (+221)': '221',
        'Serbia (+381)': '381',
        'Seychelles (+248)': '248',
        'Sierra Leone (+232)': '232',
        'Singapore (+65)': '65',
        'Sint Maarten (+1-721)': '1-721',
        'Slovakia (+421)': '421',
        'Slovenia (+386)': '386',
        'Solomon Islands (+677)': '677',
        'Somalia (+252)': '252',
        'South Africa (+27)': '27',
        'South Georgia & South Sandwich Islands (+500)': '500',
        'South Korea (+82)': '82',
        'South Sudan (+211)': '211',
        'Spain (+34)': '34',
        'Sri Lanka (+94)': '94',
        'St. Barthélemy (+590)': '590',
        'St. Helena (+290)': '290',
        'St. Kitts & Nevis (+1-869)': '1-869',
        'St. Lucia (+1-758)': '1-758',
        'St. Martin (+590)': '590',
        'St. Pierre & Miquelon (+508)': '508',
        'St. Vincent & Grenadines (+1-784)': '1-784',
        'Sudan (+249)': '249',
        'Suriname (+597)': '597',
        'Svalbard & Jan Mayen (+47)': '47',
        'Sweden (+46)': '46',
        'Switzerland (+41)': '41',
        'Syria (+963)': '963',
        'São Tomé & Príncipe (+239)': '239',
        'Taiwan (+886)': '886',
        'Tajikistan (+992)': '992',
        'Tanzania (+255)': '255',
        'Timor-Leste (+670)': '670',
        'Togo (+228)': '228',
        'Tokelau (+690)': '690',
        'Tonga (+676)': '676',
        'Trinidad & Tobago (+1-868)': '1-868',
        'Tunisia (+216)': '216',
        'Turkey (+90)': '90',
        'Turkmenistan (+993)': '993',
        'Turks & Caicos Islands (+1-649)': '1-649',
        'Tuvalu (+688)': '688',
        'U.S. Outlying Islands (+\xa0)': '\xa0',
        'U.S. Virgin Islands (+1-340)': '1-340',
        'United States (+1)': '1',
        'Uganda (+256)': '256',
        'Ukraine (+380)': '380',
        'United Arab Emirates (+971)': '971',
        'Uruguay (+598)': '598',
        'Uzbekistan (+998)': '998',
        'Vanuatu (+678)': '678',
        'Vatican City (+39-06)': '39-06',
        'Venezuela (+58)': '58',
        'Vietnam (+84)': '84',
        'Wallis & Futuna (+681)': '681',
        'Western Sahara (+212)': '212',
        'Yemen (+967)': '967',
        'Zambia (+260)': '260',
        'Zimbabwe (+263)': '263'
    }

    const reverseMapping = o => Object.keys(o).reduce((r, k) =>
        Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }), {})

    const classes = useStyles();

    const cookies = new Cookies();

    const userID = cookies.get("userID");
    const tokenID = cookies.get("tokenID");
    const secret = cookies.get("secret");

    const [fullName, setFullName] = useState("");
    const [nickname, setNickname] = useState("");
    const [yearOfStudy, setYearOfStudy] = useState("-");
    const [courseArea, setCourseArea] = useState("");
    const [level, setLevel] = useState("");
    const [courseName, setCourseName] = useState("");
    const [lineID, setLineID] = useState("");
    const [countryCode, setCountryCode] = useState("44");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [fileLink, setFileLink] = useState("");
    const [phoneMapRev, setPhoneMapRev] = useState({});
    const [countries, setCountries] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [profileExists, setProfileExists] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const {setProfile, getProfile, checkToken} = useAuth();

    function onChangeFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }

    function getData() {
        const userID = cookies.get("userID");
        const tokenID = cookies.get("tokenID");
        const secret = cookies.get("secret");

        checkToken("/cfas/hub/home", userID, tokenID, secret);

        getProfile(userID,tokenID, secret).then(async (res) => {
            setLoadingProfile(false);
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                setFullName(data.data["fullName"]);
                setNickname(data.data["nickname"]);
                setYearOfStudy(data.data["year"]);
                setCourseArea(data.data["courseArea"]);
                setLevel(data.data["level"]);
                setCourseName(data.data["courseName"]);
                setLineID(data.data["lineID"]);
                setCountryCode(data.data["phoneNumber"].split("//")[0]);
                setPhoneNumber(data.data["phoneNumber"].split("//")[1]);
                setGender(data.data["gender"]);
                setFileLink(data.data["cvLink"]);
                setProfileExists(true);
            } else if (res.status === 404 && (data.data["reason"] === "No Associated Profile Information" || data.data["reason"] === "Profile Information Not Found")) {
                console.log("New application");
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            setLoadingProfile(false);
            console.log(error)
        })
    }

    function submitHandler() {
        const cfasUserCvBucket = "cfas-user-cv-bucket";
        const bucketRegion = "eu-west-2";
        const IdentityPoolId = "eu-west-2:67102926-3c2f-4e94-b91a-8058ee31d8b8";

        if (submitting) {
            return
        }

        if (nickname === "" || fullName === "" || yearOfStudy === "" || courseArea === "" || level === "" || courseName === "" || lineID === "" || phoneNumber === "" || gender === "") {
            alert("Please fill in all the fields");
            return;
        }

        if (isNaN(+phoneNumber) === true) {
            alert("Invalid Phone Number");
            return;
        }

        setSubmitting(true);

        if (fileLink !== "" || fileName === "") {
            saveToDB("", "");
            return
        }

        AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IdentityPoolId
            })
        });

        const key = encodeURI(UUIDv4() + fileName.replace(/[^A-Za-z0-9.]/g, ""));

        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: cfasUserCvBucket,
                Key: key,
                Body: file
            }
        });

        const uploadPromise = upload.promise();

        uploadPromise.then(
            () => saveToDB(cfasUserCvBucket, key),
            function(err) {
                alert(err);
                setSubmitting(false);
                return alert("There was an error uploading your photo: " + err.message);
            }
        );
    }

    function saveToDB(bucket, key) {
        let cvLink;

        if (fileLink === "" && bucket !== "") {
            cvLink = "https://" + bucket + ".s3.eu-west-2.amazonaws.com/" + key;
        } else if (fileLink === "" && bucket === "") {
            cvLink = "";
        } else if (fileLink !== "") {
            cvLink = fileLink;
        } else {
            cvLink = "ERR!";
        }

        const formData = {
            "fullName": fullName,
            "nickname": nickname,
            "year": yearOfStudy,
            "courseArea": courseArea,
            "level": level,
            "courseName": courseName,
            "lineID": lineID,
            "phoneNumber": countryCode.replace("+", "") + "//" + (phoneNumber - 0).toString(),
            "gender": gender,
            "cvLink": cvLink
        }

        setProfile(userID, tokenID,secret,formData).then(async (res) => {
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                window.location = "/cfas/hub/home";
            } else {
                alert(data.data["reason"]);
                setSubmitting(false);
            }
        }).catch((error) => {
            console.log(error)
            setSubmitting(false);
        })
    }

    const yearList = [
        "-",
        "Graduated",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "Other"
    ]

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            checkToken("/cfas/hub/register", userID, tokenID, secret);
            getData();
            setPhoneMapRev(reverseMapping(phoneMap));
            setCountries(Object.keys(phoneMap));
            setFirstLoad(false);
        }
    })

    return (
        <ThemeProvider theme={theme}>
            {!loadingProfile && <Grid container direction="row" justifyContent="center">
                <Grid container item direction="column" justifyContent="center" padding="100px" spacing={4} sx={{maxWidth: 1600}}>
                    <Grid container item justifyContent="space-between" rowSpacing={3}>
                        <CFASHeader classes={classes} secondaryURL={"/cfas/hub/home"} secondaryText={profileExists ? "Back to Home" : undefined}>
                            CFAS Apply
                        </CFASHeader>
                    </Grid>
                    <Grid item sx={{paddingTop: 20}}>
                        <Grid container spacing={5}>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Given Name(s) and Last Name </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Nickname </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction={"row"} spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label>Gender</Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={["Male", "Female", "Other", "Prefer not to Say"]} value={gender} onChange={(e) => setGender(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Year of Study </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={yearList} value={yearOfStudy} onChange={(e) => setYearOfStudy(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Course Area </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={courseAreaList} value={courseArea} onChange={(e) => setCourseArea(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6}>
                                    <Grid item>
                                        <Label> Level </Label>
                                    </Grid>
                                    <Grid item>
                                        <Dropdown items={courseLevelList} value={level} onChange={(e) => setLevel(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Course Name </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={courseName} onChange={(e) => setCourseName(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Line ID </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={lineID} onChange={(e) => setLineID(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={5}>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={5}>
                                    <Grid item>
                                        <Label> Country Code </Label>
                                    </Grid>
                                    <Grid item >
                                        {countries[0] !== undefined && <Dropdown items={countries} value={countryCode !== "" ? phoneMapRev[countryCode][0] : countries[0]} onChange={(e) => setCountryCode(phoneMap[e.target.value])}/>}
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={1} xs={12} md={6} lg={7}>
                                    <Grid item>
                                        <Label> Phone Number </Label>
                                    </Grid>
                                    <Grid item >
                                        <InputField fullWidth type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {fileLink === "" && <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label>CV File</Label>
                                </Grid>
                                <Grid item>
                                    <SACCPaper>
                                        <Upload handleChange={e => onChangeFile(e)}></Upload>
                                    </SACCPaper>
                                </Grid>
                            </Grid>}
                            <Grid container item justifyContent="center" marginTop={fileLink === "" ? 0 : 3}>
                                <Button className={classes.submitButton} type="submit" onClick={submitHandler}> {fileLink === "" ? (submitting ? "Submitting..." : "Submit") : (submitting ? "Saving..." : "Save")} </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </ThemeProvider>
    )
};

export default AbstractSubmit;