import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SACCPaper from "./SACCPaper";

const useStyles = makeStyles(() => ({
  sponsor: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    font: "20px",
  },
}));

const SACCSponsorTier = ({ item }) => {
  console.log(item);
  const classes = useStyles();
  return (
    <SACCPaper>
      <Grid item container spacing={5} justifyContent="center">
        {item.map((item) => (
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                backgroundColor: "gray",
                borderRadius: theme.spacing(5),
                marginBottom: theme.spacing(2),
              }}
            />
            <Typography variant="p" className={classes.sponsor}>
              {item.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </SACCPaper>
  );
};

export default SACCSponsorTier;
