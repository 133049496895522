import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  contentPadding: {
    padding: theme.spacing(10, 25),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2, 5),
    },
  },
  contentNoPadding: {
  },
}));

const SACCContent = ({ children, padded }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      spacing={10}
      className={padded ? classes.contentPadding : classes.contentNoPadding}
      minWidth="700px"
      paddingY={10}
    >
      {children}
    </Grid>
  );
};

export default SACCContent;
