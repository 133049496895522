import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const buttonStyle = {
  borderRadius: "15px",
  backgroundColor: "#fb8a3e",
  color: "#ffffff",
  padding: "10px",
  textTransform: "none",
  "&:hover": {
    transition: "color 0.2s",
    backgroundColor: "#fffa9f",
    color: "#fb8a3e",
  },
};

const applyStyle = {
  width: "235px",
  height: "60px",
  padding: "15px 69px 15px 69px",
};

const discoverStyle = {
  width: "235px",
  height: "60px",
  padding: "15px 34px 15px 34px",
};

const findOutStyle = {
  width: "165px",
  height: "60px",
  padding: "15px 36px 15px 36px",
};

const learnMoreStyle = {
  backgroundColor: "white ! important",
  color: "#FB8A3E ! important",
};

const openStyle = {
  padding: "10px 20px ! important",
  backgroundColor: "#00CB39 ! important",
  color: "white ! important",
};

const upcomingStyle = {
  padding: "10px 20px ! important",
  backgroundColor: "#F9AF21 ! important",
  color: "white ! important",
};

function getButtonStyle(type) {
  if (type === "apply") {
    return applyStyle;
  } else if (type === "discover") {
    return discoverStyle;
  } else if (type === "learnMore") {
    return learnMoreStyle;
  } else if (type === "open") {
    return openStyle;
  } else if (type === "upcoming") {
    return upcomingStyle;
  } else {
    return findOutStyle;
  }
}

const SACCTextButton = styled(({ type, href, children, ...rest }) => (
  <Button
    sx={getButtonStyle(type)}
    {...rest}
    href={href}
    disabled={type === "open" || type === "upcoming"}
  >
    <Typography variant="subtitle1">{children}</Typography>
  </Button>
))(buttonStyle);

export default SACCTextButton;
