import NavbarButton from "./../NavbarButton";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useState, useEffect } from "react";
import theme from "../../theme";
import { ThemeProvider } from "@mui/material/styles";

/*
const navbarItems = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "Responsibility",
    path: "/responsibility",
  },
  {
    label: "Careers Fair",
    path: "/careers-fair",
  },
  {
    label: "Case Competition",
    path: "/case-competition",
  },
  {
    label: "Workshops",
    path: "/workshops",
  },
  {
    label: "Our Sponsors",
    path: "/sponsors",
  },
  {
    label: "About us",
    path: "/about",
  },
];
 */

const navbarItems = [
    {
        label: "Careers Fair",
        path: "/careers-fair",
    },
    {
    label: "CFAS",
    path: "/cfas/hub/home"
    }
]

const SACCNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        color="transparent"
        position="static"
        elevation={0}
        sx={{
          color: "#FF6831",
          minWidth: "400px",
          background: "linear-gradient(98.2deg, #FF7F29 0%, #FF4539 100%)",
        }}
      >
        <Toolbar>
          <img
            src={logo}
            style={{ height: "60px", padding: "30px" }}
            alt="Samaggi Samagom Logo"
          />
          <Grid container justifyContent="right">
            <Grid item>
              <Grid container>
                {navbarItems.map((item) => (
                  <Grid item>
                    <NavbarButton
                      onClick={() => {
                        navigate(item.path);
                      }}
                    >
                      {item.label}
                    </NavbarButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default SACCNavbar;
