
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import theme from "../theme";
import SACCPaper from "./SACC/SACCPaper";
import { ThemeProvider } from "@mui/material/styles"
import SACCTextButton from "./SACC/SACCTextButton";
import person_grey from "./../assets/person/person_grey.svg"
import link_white from "./../assets/link_white.svg"
import calendar_grey from "./../assets/calendar/calendar_grey.svg"
import { useEffect,useState } from "react";
import moment from 'moment'

const useStyles = makeStyles(() => ({
    workshop : {
        fontSize: "20px ! important",
        flexWrap: "wrap ! important"
    },
    title: {
        fontSize : "24px ! important",
        fontWeight: "bold ! important",
    },
    date: {
        fontSize: "14px ! important",
    },
    host: {
        fontSize : "14px ! important",
    },
    daysLeft : {
        color : "#FB8A3E",
        fontSize : "13px ! important"
    },
    buttonText: {
        marginLeft: "5px"
    }
}));

const WorkshopEvent = ({info}) => {
  const classes = useStyles();
  const [daysRemain, setDaysRemain] = useState(0)
  const date = new Date()

  const day = info.date.day
  //month is zero-indexed
  const month = info.date.month
  const year = info.date.year

  useEffect(() => {
    const now  = moment([date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()])
    const sample = moment([year,month,day])
    setDaysRemain((sample.diff(now, 'days')))
  }, [date,day,month,year])

    return (
        <ThemeProvider theme={theme}>
            <Grid item xs={6} minWidth="500px">
                <SACCPaper className={classes.border}>
                    <Grid container item justifyContent="space-between" alignItems="center" flexWrap="nowrap">
                        <Grid container item flexDirection="column" alignItems="flex-start" spacing={1}>
                            <Grid item>
                                <Typography className={classes.workshop}>
                                    {`Workshop ${info.id}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {info.title}
                                </Typography>
                            </Grid>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <img src={calendar_grey} alt="Calendar Icon"></img>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.date}>
                                        {`${day}/${month + 1}/${year}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {info.host &&
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <img src={person_grey} alt="Workshop Guest Speaker Icon"></img>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.host}>
                                        {info.host}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                        <Grid container item flexDirection="column" alignItems="flex-end" justifyContent="space-between" spacing={3}>
                            <Grid item>
                                <Typography className={classes.daysLeft}>
                                    {daysRemain > 0 ? `${daysRemain} days left` : `Completed`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <SACCTextButton>
                                    <Grid container direction="row">
                                        <img src={link_white} alt="link_white"></img>
                                        <p className={classes.buttonText}>Join Zoom</p>
                                    </Grid>
                                </SACCTextButton>
                            </Grid>
                            <Grid item> </Grid>
                        </Grid>
                    </Grid>
                </SACCPaper>
            </Grid>
        </ThemeProvider>
    );
};

export default WorkshopEvent;
