import { Grid, IconButton, Typography } from "@mui/material"
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import MailIcon from '@mui/icons-material/Mail';
import logo from "../../assets/logo.png"
import sacc_logo_white from "../../assets/sacc_logo_white.svg"


const footerIconButtons = [
    {
        icon : <InstagramIcon/>,
        link : "https://www.instagram.com/samaggi_2023/"
    },
    {
        icon : <FacebookIcon/>,
        link : "https://www.facebook.com/SamaggiSamagom/"
    },
    {
        icon : <YouTubeIcon/>,
        link : "https://www.youtube.com/channel/UCUHmjtPWHPZg5FOBf0lzFdw"
    },
    {
        icon : <MailIcon/>,
        link : "mailto:info@samaggisamagom.com"
    }
]

const openInNewTab = url => {
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
    }
}

const SACCFooter = () => {
  return (
    <div>
        <Grid container alignItems="center" justifyContent="center" sx={{bgcolor: "#FF6831", padding: "30px"}} flexDirection="column" spacing={4}>
            <Grid item>
               <img style={{width: "150px"}} alt="sammagi" src={logo}/>
            </Grid>
            <Grid item>
                <img style={{width: "250px"}} alt="sacc_white" src={sacc_logo_white}/>
            </Grid>
            <Grid item>
                <Typography variant="h5" color="white">
                    Samaggi Academic Conference and Careers Fair 2024
                </Typography>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center">
            {footerIconButtons.map((item) => (
                <Grid item>
                    <IconButton onClick={() => openInNewTab(item.link)}> {item.icon} </IconButton>
                </Grid>
            ))}
            </Grid>     
        </Grid>         
    </div>
  )  
}


export default SACCFooter;
