import SACCOverview from "./../../common/SACC/SACCOverview";
import SACCTextButton from "../../common/SACC/SACCTextButton";
import theme from "../../theme";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import { Grid } from "@mui/material";
import SACCContent from "../../common/SACC/SACCContent";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SACCFooter from "../../common/SACC/SACCFooter";
import SACCPaper from "../../common/SACC/SACCPaper";
import erawan_elephant_orange from "../../assets/erawan_elephant_orange.svg";
import SACCSponsorTier from "../../common/SACC/SACCSponsorTier";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  sponsor: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    fontSize: "40px",
    color: "#FB8A3E",
  },
  infoHeader: {
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    fontSize: "32px ! important",
    color: "#FB8A3E",
    fontFamily: "Montserrat ! important",
  },
  infoText: {
    fontWeight: `${theme.typography.fontWeightLight} !important`,
    fontSize: "20px",
    fontFamily: "Montserrat ! important",
  },
  platinum: {
    color: "#D7C9A2",
    fontSize: "32px",
  },
  platinumName: {
    fontSize: "25px",
    fontWeight: `${theme.typography.fontWeightBold} !important`,
  },
  platinumThaiName: {
    fontSize: "15px",
    fontWeight: `${theme.typography.fontWeightLight} !important`,
  },
  gold: {
    color: "#FFD700",
    fontSize: "32px",
  },
  silver: {
    color: "#C0C0C0",
    fontSize: "32px",
  },
}));

const overviews = [
  {
    live: "Now Open",
    title: "Career Fair",
    description:
      "The Careers Fair will bring notable companies from Thailand into a one-stop shop where participants and network, apply for jobs, and conduct interviews in one place. From finance to engineering to retail, we are inviting a diverse set of business to give attendees a representative sample of the future that is open to them. We will also host workshops with some companies to allow attendees to develop application and industry-specific skills to help forward their careers.",
    date: "11-13 January 2023",
  },
  {
    live: "Upcoming",
    title: "Case Competition",
    description:
      "The Samaggi Case Competition brings participants to work collaboratively and gain an understanding of a company's background to propose a solution to one of the company’s objectives - this year we are exploring the energy industry, and devising digital solutions to promote sustainable growth. The business strategies created by the participating teams will be pitched to the company itself and a panel of judges.",
    date: "16-20 January 2023",
  },
  {
    live: "Upcoming",
    title: "Seminar",
    description:
      "The Samaggi Seminar will provide a forum for the discussion of ideas between students and well established speakers from multiple industries in Thailand. Speakers will provide an insight into how hope and responsibilities are manifested in their industries, and panels and workshops will allow students to engage them in intellectual debate and knowledge sharing.",
    date: "12-14 February 2023",
  },
  {
    live: "Upcoming",
    title: "Abstract Competition",
    description:
      "The Abstract Competition is open to students who have worked on an abstract they are proud of. We invite abstract to be sent and judged, and finalists will be able to pitch their research online to an interested audience. This competition will showcase Thai talent and allow curious minds to connect and forward their careers together.",
    date: "20-22 February 2023",
  },
];

const platinum = [
  {
    name: "Sponsors Name",
    thaiName: "Sponsor’s Thai Name",
    link: "",
  },
  {
    name: "Sponsors Name",
    thaiName: "Sponsor’s Thai Name",
    link: "",
  },
  {
    name: "Sponsors Name",
    thaiName: "Sponsor’s Thai Name",
    link: "",
  },
];

const gold = [
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
];
const silver = [
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
  {
    name: "Sponsors Name",
  },
];

const Home = () => {
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <SACCPageHeader />
      </Grid>
      <SACCContent padded>
        <Grid item>
          <SACCPaper>
            <Grid container justifyContent="center">
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                rowSpacing={5}
                xs={7}
              >
                <Grid item xs={1}>
                  <img
                    src={erawan_elephant_orange}
                    alt="Erawan Elephant Orange"
                    style={{ width: "100px" }}
                  />
                </Grid>
                <Grid item>
                  <Typography align="center" className={classes.infoHeader}>
                    What is Samaggi Academic Conference and Careers Fair 2023
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="center" className={classes.infoText}>
                    The Samaggi Academic Conference and Careers Fair (SACC2023)
                    consists of a series of events that provide a medium for
                    Thai students of all educational backgrounds in the United
                    Kingdom to connect and exchange their knowledge and ideas
                    with peers, alongside exploring their potential career
                    opportunities. Now in its 15th year, SACC2023 will be made
                    up of 4 events: the Case Competition, Careers Fair, Samaggi
                    Seminar, and Abstract Competition.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </SACCPaper>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          {overviews.map((item) => (
            <SACCOverview item={item} />
          ))}
        </Grid>
        <Grid item>
          <Typography variant="p" className={classes.sponsor}>
            Sponsors
          </Typography>
        </Grid>
        <Grid item container direction="column" rowSpacing={3}>
          <Grid item>
            <Typography variant="p" className={classes.platinum}>
              Platinum
            </Typography>
          </Grid>
          <Grid item container spacing={3} justifyContent="center">
            {platinum.map((item) => (
              <Grid item xs={12} lg={4}>
                <SACCPaper>
                  <Grid container direction="column">
                    <Grid item>
                      <Box
                        sx={{
                          width: "100%",
                          height: "200px",
                          backgroundColor: "gray",
                          borderRadius: theme.spacing(5),
                          marginBottom: theme.spacing(2),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="p" className={classes.platinumName}>
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: theme.spacing(5) }}>
                      <Typography
                        variant="p"
                        className={classes.platinumThaiName}
                      >
                        {item.thaiName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <SACCTextButton
                        type="learnMore"
                        endIcon={<ArrowRightAltIcon />}
                      >
                        Learn More
                      </SACCTextButton>
                    </Grid>
                  </Grid>
                </SACCPaper>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item container direction="column" rowSpacing={3}>
          <Grid item>
            <Typography variant="p" className={classes.gold}>
              Gold
            </Typography>
          </Grid>
          <Grid item>{<SACCSponsorTier item={gold} />}</Grid>
        </Grid>
        <Grid item container direction="column" rowSpacing={3}>
          <Grid item>
            <Typography variant="p" className={classes.silver}>
              Silver
            </Typography>
          </Grid>
          <Grid item>{<SACCSponsorTier item={silver} />}</Grid>
        </Grid>
      </SACCContent>
      <SACCFooter />
    </Grid>
  );
};

export default Home;
