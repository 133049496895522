import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import SACCContent from "../../common/SACC/SACCContent";
import CaseCompPaper from "../../common/SACC/CaseCompetition/CaseCompPaper";
import { Typography, Grid } from "@mui/material";
import CaseCompTimeline from "../../common/SACC/SACCTimeline";
import SACCPaper from "../../common/SACC/SACCPaper";
import SACCJudges from "../../common/SACC/SACCJudges";
import CaseCompFooter from "../../common/SACC/SACCFooter";
import CaseCompObjectives from "../../common/SACC/CaseCompetition/CaseCompObjectives"
import { ThemeProvider } from "@mui/material/styles"
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import WorkshopEvent from "../../common/WorkshopEvent";
import SACCPresentedBy from "../../common/SACC/SACCPresentedBy";
import pttLogo from "../../assets/logos/PTTEP_Logo.png"
import {useEffect} from "react";

const info = [
  {
    title : "What is a Case Competition?",
    description : "In the simplest terms, a case competition is an academic competition bringing students together to solve a case pertaining to an issue faced by a company. Critical thinking and analysis are key as teams of students produce solutions in the form of a written report AND a presentation to be delivered to judges."
  },
  {
    title : "Why participate? ",
    description : "Case competitions are an incredible opportunity to meet and discover people with varying interests and ideas, with the chance to network with potential employers and industry professionals. An event to unleash your academic, research, and creative prowess, our case competition will help you engage more deeply with the world we live in and make you consider how we can each make an impact on our society. This is your opportunity to show potential employers your knowledge and problem-solving skills, and prove your commitment to striving towards a more sustainable future!"
  }
]

const mentors = [
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
]

const judges = [
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
  {
    profilePicture: "Profile Picture",
    name: "Lorem ipsum",
    contact: "Lorem ipsum dolor",
    description: "",
    isAvailable: false
  },
];

const faq = [
  {
    question: "What is this year’s theme about?",
    answer:
      "We will explore the energy industry, especially digital energy in order to create a financially, socially, and environmentally responsible corporate policy without compromising economic growth which could be in line with the company’s strategy and strategic direction. If this interests you, join us!",
  },
  {
    question: "Who can register?",
    answer:
      "You must be a Thai national currently studying in the UK as an undergraduate or postgraduate student. Most importantly, you are expected to be able to present in-person in London on Pitch Day - Sunday 4 December 2022, and take part in the online sessions throughout the duration of the case competition.",
  },
  {
    question: "When does the application close?",
    answer:
      "Participants can sign up from Saturday 15 October onwards to Sunday 30 October 2022.",
  },
  {
    question: "When can I start cracking Samaggi’s Case?",
    answer:
      "On Monday 31 October 2022, we will email you the case booklet containing all the prompt details and information to get you started!",
  },
  {
    question: "Do I need a team to join?",
    answer:
      "You can register as individuals or as a team of 3-5 members. If you register as an individual, we will assign you to a team.",
  },
  {
    question: "Will the case be specific to a company?",
    answer:
      "Yes, the prompt will be focused on solutions for our main sponsor, which will be an energy company based in Thailand. Our main sponsor will be revealed on Monday 31 October 2022.",
  },
  {
    question: "Do I need any previous experience?",
    answer:
      "No, students of ALL experience levels are welcome! It is beginner-friendly too, with online workshops introducing and equipping you on how to crack the case along with improvement sessions with experts who will help you throughout the competition. So even if you are new to solving business cases, we will ensure you get the support needed.",
  },
  {
    question: "How many teams will be in the final round?",
    answer:
      "6 finalist teams will be chosen for Pitch Day in London on Sunday 4 December 2022. ",
  },
  {
    question: "Who can we contact for queries on the Case Competition?",
    answer:
      "Feel free to DM us on Instagram, Facebook or send us an email at case-competition@samaggisamagom.com",
  },  
  {
    question: "What will be given to the participants as the prize?",
    answer: "The winning team will receive: £1000, the team in second place (1st runner up) will receive £500, and the team in third place (2nd runner up) will receive £300."
  }
];

const useStyles = makeStyles(() => ({
  content: {
    padding: theme.spacing(10, 25),
  },
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    color: "#FB8A3E",
    marginBottom: `${theme.spacing(4)} ! important `,
    fontSize: "27px !important"
  },
  faqQuestions: {
    fontWeight: "bold ! important",
    marginBottom: `${theme.spacing(2)} ! important `,
  },
  sponsorPaper: {
    padding: theme.spacing(5),
    borderRadius: `${theme.shape.borderRadius * 5}px ! important`,
  },
}));

const timeline =
  [
    {
      title: "Pre-Event",
      description: "Sat 15 Oct - Fri 4 Nov 2022",
      events: [
        {
          date: "Sat 15 Oct 2022",
          title: "Registration Opens",
          description: "Case Competition participant sign-up form opens.",
        },
        {
          date: "Fri 4 Nov 2022",
          title: "Registration Closes",
          description: "Case Competition participant sign-up form closes.",
        },
        {
          date: "Fri 5 Nov 2022",
          title: "Announcements",
          description: "Case and Sponsor Announcement via email.",
        }
      ]
    },
    {
      title: "Preliminary Rounds",
      description: "Fri 4 Nov - Fri 11 Nov 2022",
      events: [
        {
          date: "Fri 4 - Fri 11 Nov 2022",
          title: "Preliminary Round",
          description: "Participants compete to write business proposals to tackle the issue posed by the prompt and submit a final plan."
        },
        {
          date: "Sat 5 Nov 2022",
          title: "First Case Competition Workshop: Introduction to Business Proposals",
          description: "See \"Workshops\" below."
        },
        {
          date: "Fri 11 Nov 2022",
          title: "Preliminary Round's Submission Deadline",
          description: "Teams submit their final plan to be shortlisted by the judges."
        },
        {
          date: "Mon 14 Nov 2022",
          title: "Preliminary Announcement",
          description: "Judges' verdicts on Preliminary Round team eliminations - 12 teams to remain"
        }
      ]
    },
    {
      title: "Semi Finals",
      description: "Mon 14 Nov - Wed 23 Nov 2022",
      events: [
        {
          date: "Tue 15 Nov 2022",
          title: "Second Case Competition Workshop",
          description: "This may include activities such as developing problem-solving and pitching skills, as well as preparing participants for Semi Finals 'Emergency Round'"
        },
        {
          date: "Wed 16 - Fri 18 Nov 2022",
          title: "SEMI FINALS - Emergency Round",
          description: "Involves an 'Emergency Emergency Prompt’, presenting participants with an urgent scenario that requires quick thinking and decision-making."
        },
        {
          date: "Wed 23 Nov 2022",
          title: "Semi-finalists Announcement",
          description: "Judges’ verdicts on Semi Finals team eliminations – 6 teams to remain."
        }
      ]
    },
    {
      title: "Finals",
      description: "Thu 24 Nov - Sun 4 Dec 2022",
      events: [
        {
          date: "Thu 24 Nov - Sat 3 Dec 2022",
          title: "Mentoring Session",
          description: "The 6 finalist teams receive personalised mentorship, with two mentors per team, introducing them to important details about an ideal pitch."
        },
        {
          date: "Sun 4 Dec 2022",
          title: "Finals",
          description: "To be held in London, the final round will be a largely in-person event for the general public and sponsors to interact with team representatives and the site for the finalists’ final presentation to judges. This will consist of a 15-minute presentation followed by a 5-minute Q&A from the judges and audience."
        }
      ]
    }
];

const workshopInfo = [
  {
    id : 1,
    title : "Introduction to Business Proposal and Design Thinking Workshop",
    date : {
      day: 5,
      //month starts at 0 (0 : January - 11 : December)
      month : 10,
      year: 2022
    },
    host : "PTTEP & K. Pornkamol Prapapornvorakul",
    link : ""
  },
  {
    id : 2,
    title : "Quick Thinking and Problem Solving",
    date : {
      day: 15,
      //month starts at 0 (0 : January - 11 : December)
      month : 10,
      year: 2022
    },
    host : "TBA",
    link : ""
  }
]

const CaseCompetition = () => {

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }, []);


  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Grid item>
          <SACCPageHeader/>
        </Grid>

        <SACCContent padded>
          <Grid item container direction="column" alignItems="stretch" sx={{marginBottom: 2}}>
            <CaseCompPaper/>
          </Grid>

          <div id="scroll-target"></div>

          {info.map((item) => (
            <Grid item>
              <SACCPaper>
                <Typography gutterBottom variant="h6" className={classes.title}>
                  {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
              </SACCPaper>
            </Grid>
          ))}
        </SACCContent>

        <div id="prompt"></div>
        <SACCContent>
          <Grid item>
            <CaseCompObjectives/>
          </Grid>
        </SACCContent>

        <SACCContent padded>
          <Grid item>
            <SACCPresentedBy withBackground companyName="PTT Exploration and Production Public Company Limited" src={pttLogo}/>
          </Grid>
        </SACCContent>

        <SACCContent padded>
          <Grid item>
            <Typography gutterBottom variant="h6" className={classes.title}>
              Timeline
            </Typography>
            <CaseCompTimeline timeline={timeline} />
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography gutterBottom variant="h6" className={classes.title}>
                Workshops
              </Typography>
            </Grid>  
            <Grid item container spacing={5} flexWrap="wrap">
              {workshopInfo.map((item) => (
                <WorkshopEvent info={item} />
              ))}
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item>
              <Typography gutterBottom variant="h6" className={classes.title}>
                Mentors
              </Typography>
            </Grid>
            <Grid item container spacing={5}>
              {mentors.map((item) => (
                  <SACCJudges item={item} />
              ))}
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item>
              <Typography gutterBottom variant="h6" className={classes.title}>
                Judges
              </Typography>
            </Grid>
            <Grid item container spacing={5}>
              {judges.map((item) => (
                <SACCJudges item={item} />
              ))}
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <Typography gutterBottom variant="h6" className={classes.title} id="faq">
                Frequently Asked Questions (FAQ)
              </Typography>
            </Grid>
            <Grid item container direction="column" rowSpacing={4}>
              {faq.map((item) => (
                <Grid item>
                  <SACCPaper>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.faqQuestions}>
                          Q : {item.question}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{item.answer}</Typography>
                      </Grid>
                    </Grid>
                  </SACCPaper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </SACCContent>
        <CaseCompFooter/>
      </Grid>
    </ThemeProvider>
  );
};

export default CaseCompetition;
