import {Grid, ThemeProvider, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import theme from "../../../theme";
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import CFASStatusBlock from "../../../common/SACC/CareersFairContext/CFASStatusBlock";
import SACCPaper from "../../../common/SACC/SACCPaper";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";

const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: "700",
        fontSize: "32px",
    },
    text: {
        color: "#878787",
    },
    logo: {
        width: "50px",
    },
    dropdownMenu: {
        padding: theme.spacing(0, 1),
        width: "100%",
        borderRadius: "15px",
        fontSize: "24px",
        fontWeight: 500,
    },
    dropdownTitle: {
        fontWeight: 600,
        color: "#A3A3A3",
        fontSize: "24px",
    },
    companyPaper: {
        padding: theme.spacing(3, 3),
        borderRadius: "20px ! important",
    },
    companyTitle: {
        fontSize: "30px",
        fontWeight: 600,
    },
    logoffButton: {
        background: "#FB8A3E",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px",
        "&:hover": {
            background: "#FB8A3E",
        },
    },
    cfasApplyLink: {
        color: "#FB8A3E",
        fontWeight: "600",
        textDecoration: "none"
    }
}));


const Track = (props) => {

    const {listApplication} = useAuth();

    const classes = useStyles();

    const [applications, setApplications] = useState({});
    const [loading, setLoading] = useState(true);

    function load() {
        listApplication().then(async (appRes) => {
            const data = await appRes.json();
            if (appRes.status === 200) {
                setApplications(data.data["applications"]);
                console.log(data.data["applications"])
            } else {
                alert(data.data["reason"]);
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error)
        })
    }

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            load();
            setFirstLoad(false);
            props.setShowCover(false);
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                padding="100px"
                spacing={4}
            >
                <CFASHeader secondaryText={"Back to Hub"} secondaryURL={"/cfas/hub/home"}>
                    CFAS Track
                </CFASHeader>
                <Grid item container direction={"row"} spacing={5} marginTop={4} alignItems="stretch">
                    {loading && (
                        <Paper className={classes.companyPaper} sx={{width: "100%", marginTop: 6}} elevation={3}>
                            <Typography className={classes.companyTitle} sx={{textAlign: "center", marginBottom: 2}}>
                                Loading...
                            </Typography>
                            <Typography sx={{textAlign: "center"}}>
                                Please wait as we load your applications
                            </Typography>
                        </Paper>
                    )}
                    {!loading && applications.length === 0 && (
                        <Paper className={classes.companyPaper} sx={{width: "100%", marginTop: 6}} elevation={3}>
                            <Typography className={classes.companyTitle} sx={{textAlign: "center", marginBottom: 2}}>
                                No Application
                            </Typography>
                            <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"}>
                                <Grid item>
                                    <Typography sx={{textAlign: "center"}}>
                                        You have not applied for any position yet. Apply now at&nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.cfasApplyLink} onClick={() => window.location = "/cfas/apply/home"}>
                                        CFAS Apply
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Paper>
                    )}
                    {!loading && applications.map((application) => (
                        <Grid item xs={12} lg={6} xl={4}>
                            <SACCPaper className={classes.companyPaper} elevation={6} style={{height:'100%', boxSizing: "border-box"}}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    rowSpacing={1}
                                >
                                    <Grid item>
                                        <Typography className={classes.companyTitle}>
                                            {application["company-name"]}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{application["position-name"]}</Typography>
                                    </Grid>
                                    <CFASStatusBlock classes={classes} application={application} withButton/>
                                </Grid>
                            </SACCPaper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Track;
