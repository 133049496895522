import {useEffect, useMemo, useState} from "react";
import erawan_orange from "../../../assets/erawan_elephant_orange.svg";
import {Typography, Grid, ThemeProvider, Button} from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import {courseLevelList, ssoCourseAreaList} from "../../../assets/options";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown";
import { useTable } from "react-table"
import Cookies from "universal-cookie";
import { useAuth } from "../../../contexts/AuthContext";


const useStyles = makeStyles(() => ({
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: "700",
        fontSize: "32px",
    },
    text: {
        color: "#878787",
    },
    logo: {
        width: "50px",
    },
    logoffButton: {
        background: "#FB8A3E",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px",
        "&:hover": {
            background: "#FB8A3E",
        },
    },
    tableWrapper: {
        borderCollapse: "collapse",
        width: "100%",
        fontSize: "24px",
    },
    tableData: {
        padding: "8px",
        fontSize: "20px",
        backgroundColor: "#ececec",
        marginLeft: "20px"
    },
    tableRow: {
        lineHeight: "50px"
    },
    tableHeader: {
        paddingTop: "12px",
        paddingBottom: "12px",
        textAlign: "left",
        backgroundColor: "#b0b0b1",
        color: "#000000",
        padding: "8px",
        fontSize: "24px",
        marginLeft: "20px"
    }
}));

const CompanyDashboard = () => {

    const {companyApplications ,checkToken} = useAuth();
    const classes = useStyles();

    const [courseArea, setCourseArea] = useState("All");
    const [education, setEducation] = useState("Any");
    const [status, setStatus] = useState("Any");

    const columns = useMemo(
        () => [
            {
                Header: "Time",
                accessor: "time-description"
            },
            {
                Header: "Full Name",
                accessor: "fullName",
            },
            {
                Header: "Year",
                accessor: "year",
            },
            {
                Header: "Course",
                accessor: "courseName",
            },
            {
                Header: "Area",
                accessor: "courseArea",
            },
            {
                Header: "Level",
                accessor: "level",
            },
            {
                Header: "Position",
                accessor: "position-name",
            },
            {
                Header: "Status",
                accessor: "status"
            }
        ],
        []
    )

    const [data, setData] = useState([]);

    function load() {
        const filters = [];

        if (courseArea !== "All") {
            filters.push({
                "field": "courseArea",
                "filterType": "EQUALS",
                "value": courseArea,
                "includesEmpty": false
            })
        }
        if (education !== "Any") {
            filters.push({
                "field": "level",
                "filterType": "EQUALS",
                "value": education,
                "includesEmpty": false
            })
        }
        if (status !== "Any") {
            filters.push({
                "field": "status",
                "filterType": "EQUALS",
                "value": status.toUpperCase(),
                "includesEmpty": false
            })
        }

        companyApplications(companyID, tokenID, secret, filters).then(async (appRes) => {
            const data = await appRes.json();
            if (appRes.status === 200) {
                setData(data.data);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const cookies = new Cookies();
    const companyID = cookies.get("companyID");
    const tokenID = cookies.get("cTokenID");
    const secret = cookies.get("cSecret");

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            checkToken("/cfas/company/home", companyID, tokenID, secret, true);
            load();
            setFirstLoad(false);
        }
    })


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    const dropdownMenus = [
        {
            title: "Course Area",
            options: ["All", ...ssoCourseAreaList],
            value: courseArea,
            updater: setCourseArea
        },
        {
            title: "Education Level",
            options: ["Any", ...courseLevelList],
            value: education,
            updater: setEducation
        },
        {
            title: "Status",
            options: ["Any", "Accepted", "Interview", "Unsuccessful", "Submitted"],
            value: status,
            updater: setStatus
        }
    ];

    function reload(setter, event) {
        setData([]);
        console.log(event.target.value);
        setter(event.target.value);
        setFirstLoad(true);
    }

    function handleView(applicationID) {
        window.open("/cfas/company/view-application?applicationID=" + applicationID, "_blank");
    }

    function handleLogOut() {
        cookies.set("cTokenID", "", {path:"/"});
        cookies.set("cSecret", "", {path:"/"});
        cookies.set("companyID", "", {path:"/"});
        window.location = "/cfas/company/sign-in";
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                padding="100px"
                rowSpacing={10}
            >
                <Grid container item justifyContent="space-between" direction={"row"}>
                    <Grid item>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item>
                                <img className={classes.logo} src={erawan_orange} alt={"Logo"}/>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.applicationTitle} variant="h5">
                                    CFAS for Companies
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} container alignItems="center">
                        <Grid item xs={12}>
                            <Button className={classes.logoffButton} onClick={handleLogOut}>Log Out</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction={"row"} columnSpacing={3}>
                    {dropdownMenus.map((item) => (
                        <Grid item md={3} sm={12}>
                            <Dropdown items={item.options} label={item.title} value={item.value} onChange={(e) => reload(item.updater, e)}></Dropdown>
                        </Grid>
                    ))}
                </Grid>
                <Grid item>
                    <table {...getTableProps()} className={classes.tableWrapper}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className={classes.tableHeader}>{column.render("Header")}</th>
                                ))}
                                <th className={classes.tableHeader}>View</th>
                            </tr>
                        ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className={classes.tableRow}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} className={classes.tableData}>{cell.render("Cell")}</td>
                                    })}
                                    <td className={classes.tableData} style={{marginRight: "20px"}}>
                                        <Button className={classes.logoffButton} onClick={() => handleView(data[row.index]["application-id"])}>View</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default CompanyDashboard;
