import { TextField } from "@mui/material"

const style = {
    input : {
        color : "#878787",
        fontWeight : "500",
        fontSize : "24px"

    }, 
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border : "3px solid #878787",
            borderRadius : "15px",
        },
        '&:hover fieldset': {
            borderColor: '#878787',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
}

const InputField = (props) => {
    return (
        <TextField {...props} sx={style}></TextField>
    )
}

export default InputField;