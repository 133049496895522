import { Typography } from "@mui/material"

const style ={
    fontWeight : "500",
    fontSize: "24px",
}

const Label = (props) => {
    return (
        <Typography color="#878787" sx={style}>{props.children}</Typography>
    )
}

export default Label;