import Grid from "@mui/material/Grid";
import SACCPageHeader from "../../common/SACC/SACCPageHeader";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import SACCAwareness from "../../common/SACC/SACCAwareness";
import SACCContent from "../../common/SACC/SACCContent";
import SACCDivider from "../../common/SACC/SACCDivider";
import SACCFooter from "../../common/SACC/SACCFooter";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const placeholder =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
const awarenessItems = [
  {
    title: "Environmental Awareness",
    content: placeholder,
    image: "Image",
  },
  {
    title: "Social Awareness",
    content: placeholder,
    image: "Image",
  },
  {
    title: "Financial Awareness",
    content: placeholder,
    image: "Image",
  },
];

const Responsibility = () => {
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://sacc.samaggisamagom.com/cfas"
  }, []);

  return (
    <Grid container direction="column">
      <Grid item>
        <SACCPageHeader />
      </Grid>
      <SACCContent padded>
        <Grid item>
          <Typography
            align="center"
            gutterBottom
            variant="h6"
            className={classes.title}
          >
            Our Responsibility
          </Typography>
          <Typography align="center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Grid>
        <SACCDivider />

        {awarenessItems.map((item) => (
          <SACCAwareness item={item} />
        ))}
      </SACCContent>
      <SACCFooter/>
    </Grid>
  );
};

export default Responsibility;
