import Grid from "@mui/material/Grid";
import theme from "../../theme";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SACCIcon from "./SACCIcon";
import SACCPaper from "./SACCPaper";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  judgeIcon: {
    width: "100% ! important",
    height: "100% ! important",
    backgroundColor: "	#A9A9A9",
    color: "white",
  },
  judgeDescription: {
    padding: theme.spacing(2),
  },
  field: {
    color : "#FB8A3E"
  }
}));

const SACCJudges = ({ item }) => {
  const classes = useStyles();
  if (item.isAvailable) {
    return (
      <Grid item xs={6} minWidth="500px">
        <SACCPaper elevation={6}>
          <Grid container columnSpacing={3}>
            <Grid item xs={5}>
              <PersonIcon className={classes.judgeIcon} />
            </Grid>
            <Grid
              item
              xs={7}
              container
              direction="column"
              rowSpacing={3}
              className={classes.judgeDescription}
            > 
              <Grid item>
                <Typography variant="h6" className={classes.field}>{item.field}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">{item.name}</Typography>
              </Grid>
              <Grid item>
                <Typography>{item.university}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </SACCPaper>
      </Grid>
    )
  } else {
    return (
      <Grid item xs={6} minWidth="400px">
        <SACCPaper elevation={6}>
          <Typography variant="h6" sx={{fontWeight: `600`, textAlign: "center"}}>To be announced!</Typography>
        </SACCPaper>
      </Grid>
    )
  }
};

export default SACCJudges;
