import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import theme from "../../theme";
import { useLocation } from "react-router-dom";
import sacc_logo_white from "../../assets/sacc_logo_white.svg";
import SACCCaseComp from "./SACCCaseComp";

const useStyles = makeStyles(() => ({
  headerContainer: {
    minHeight: "30vh",
    padding: theme.spacing(15, 10),
    minWidth: "700px",
    background: "linear-gradient(98.2deg, #FF7F29 0%, #FF4539 100%)",
  },
  headerTitle: {
    fontWeight: `${theme.typography.fontWeightBold}!important`,
    color: "white",
    fontSize: "60px",
  },
  subheader: {
    fontWeight: `${theme.typography.fontWeightLight} !important`,
    fontSize: "30px",
    color: "white",
  },
}));

const headerInfo = [
  {
    path: "/",
    title: "Samaggi Academic Conference and Careers Fair 2023",
    subheader: (
      <img src={sacc_logo_white} alt="SACC Logo" style={{ width: "300px" }} />
    ),
    additionalComponent: <SACCCaseComp />,
  },
  {
    path: "/responsibility",
    title: "Responsibility",
  },
  {
    path: "/careers-fair",
    title: "Careers Fair & Seminar",
    subheader: "Samaggi Academic Conference & Careers Fair 2024",
  },
  {
    path: "/case-competition",
    title: "Case Competition",
    subheader: "Samaggi Academic Conference and Careers Fair 2023",
  },
  {
    path: "/about",
    title: "About Us",
  },
  {
    path: "/sponsors",
    title: "Our Sponsors",
  },
  {
    path: "/workshops",
    title: "Workshops",
  },
];

const SACCPageHeader = () => {
  const location = useLocation();
  const classes = useStyles();
  return (
    <>
      {headerInfo.map(
        (item) =>
          item.path === location.pathname && (
            <Grid
              container
              direction={item.additionalComponent ? "row" : "column"}
              justifyContent={
                item.additionalComponent ? "space-evenly" : "center"
              }
              className={classes.headerContainer}
              rowSpacing={5}
            >
              <Grid
                item
                container
                direction="column"
                lg={item.additionalComponent ? 5 : 12}
                xs={12}
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant="p"
                    className={classes.headerTitle}
                    gutterBottom
                  >
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="p"
                    className={classes.subheader}
                    gutterBottom
                  >
                    {item.subheader}
                  </Typography>
                </Grid>
              </Grid>
              {item.additionalComponent && (
                <Grid item xs={12} lg={5}>
                  {item.additionalComponent}
                </Grid>
              )}
            </Grid>
          )
      )}
    </>
  );
};

export default SACCPageHeader;
