import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import SACCTextButton from "./SACCTextButton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import SACCPaper from "./SACCPaper";

const useStyles = makeStyles(() => ({
  eventHeader: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
  },
}));

const SACCSponsorEvent = ({ item }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Grid item xs={6}>
      <SACCPaper elevation={12}>
        <Grid container direction="column" rowSpacing={3}>
          <Grid item>
            <Typography
              variant="h5"
              className={classes.eventHeader}
              gutterBottom
            >
              {item.sponsor}
            </Typography>
            <Typography>{item.event}</Typography>
          </Grid>
          <Grid item>
            <Typography>{item.description}</Typography>
          </Grid>
          <Grid item>
            <SACCTextButton
              onClick={() => navigate("/workshops")}
              endIcon={<ArrowRightAltIcon />}
            >
              Find Out
            </SACCTextButton>
          </Grid>
        </Grid>
      </SACCPaper>
    </Grid>
  );
};

export default SACCSponsorEvent;
