import { useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Icon from "./../../../assets/upload.svg"
const formStyle = {
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
    height : "500px",
    width : "100%"
}

const buttonStyle = {
    cursor: "pointer",
    padding: "0.25rem",
    border: "none",
    color: "#FB8A3E",
    backgroundColor: "transparent",
    fontWeight : "700",
    fontSize : "32px"
}

const labelStyle = {
    border : "3px solid #878787",
    borderRadius : "15px",
    display : "flex",
    justifyContent : "center",
    alignItems : "center",
    height : "100%",
    width: "100%",
    position: "relative"
  
}
const Upload = ({cover, handleChange}) => {

  const [dragActive, setDragActive] = useState(false)
  const [file, setFile] = useState("")
  const inputRef = useRef(null)

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleChange(e)
      setFile(e.target.files[0].name)
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleChange(e)
      setFile(e.dataTransfer.files[0].name)
    }
  }

  const onButtonClick = () => {
    inputRef.current.click()
  }

  const fileType = cover ? "cover letter" : "CV"

  return (

      <form style={formStyle} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" accept="application/pdf" id="file-upload" style={{display : "none"}} onChange={onChange} />
        <label style={labelStyle} htmlFor="file-upload" className={dragActive ? "drag-active" : "" }>
         {file ? 
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Typography sx={{color : "#878787", fontWeight : "700", fontSize : "32px"}} >{file}</Typography>
            </Grid>
          </Grid>
          : 
          <Grid container direction="column" spacing={1}>
              <Grid item>
                <img src={Icon} alt={"File Upload Icon"}/>
              </Grid>
              <Grid container item direction="row" justifyContent="center" spacing={1}>
                  <Grid item>
                      <Typography sx={{color : "#878787", fontWeight : "700", fontSize : "32px"}}>Drop your {fileType} here, or </Typography>
                  </Grid>
                  <Grid item>
                      <button style={buttonStyle} onClick={onButtonClick}>Browse</button>
                  </Grid>
              </Grid>
              <Grid item>
                  <Typography style={{color : "#A9a9a9", fontWeight : "600", fontSize : "24px"}}>Make sure that your {fileType} is in .pdf</Typography>
              </Grid>
              <Grid item>
                <Typography style={{color : "#A9a9a9", fontWeight : "400", fontSize : "20px", marginLeft : "10px", marginRight : "10px"}}>{cover ? "Note: You will not be able to change your file upload." : "A CV is required to submit an application. Uploading a CV is optional for attending the Careers Fair and Seminar event. If left blank, you will be able to upload it later."}</Typography>
              </Grid>
          </Grid> }
        </label>
        {dragActive && <div style={{position: "absolute", width: "100%", height: "100%",inset : "0"}} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
      </form>
  )
}
 
Upload.defaultProps = {
  handleChange: () => console.log("file change")
}
export default Upload;