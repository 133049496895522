import {Grid, styled} from "@mui/material";
import Button, {ButtonProps} from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {ssoPermsURL, ssoURL} from "../TSsupport";
import {useSearchParams} from "react-router-dom";

export const BaseButton = styled(Button)(() => ({
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontWeight: "500",
    borderRadius: "5px",
    minHeight: "50px",
    maxHeight: "70px",
    fontSize: "16px",
    borderWidth: "3px",
    border: `1px solid ${"#FF9031"}`,
    boxShadow: "0 1px 3px #9E9E9E",
    "&:hover": {
        backgroundColor: "#EE8021",
        border: `1px solid ${"#EE8021"}`,
        color: "#FFFFFF",
    }
}))

export interface SSOButtonProps extends ButtonProps {
    textVariant?: string,
    forwardParams?: boolean
}

export const SSOButton: React.FC<SSOButtonProps> = (props) => {
    const [image, setImage] = useState("https://general-assets-ss.s3.eu-west-2.amazonaws.com/logo_no_text_orange.png");
    const [buttonText, setButtonText] = useState("Continue with Samaggi Account");
    const [params, ] = useSearchParams();

    function onHover() {
        setImage("https://general-assets-ss.s3.eu-west-2.amazonaws.com/logo_no_text_white.png")
    }

    function onStopHover() {
        setImage("https://general-assets-ss.s3.eu-west-2.amazonaws.com/logo_no_text_orange.png")
    }

    const variants = {
        "continue": "Continue with Samaggi Account",
        "sign-in": "Sign In with Samaggi Account",
        "sign-up": "Sign Up with Samaggi Account"
    }

    useEffect(() => {
        if (props.textVariant !== undefined && Object.keys(variants).includes(props.textVariant)) {
            setButtonText(variants[props.textVariant as keyof typeof variants])
        }

        console.log("Added event listener.")
        window.addEventListener("sso-state", () => {
            setTimeout(() => {
                console.log("ATTEMPT CLOSE");
                window.close()
            }, 500)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonText])

    function createURL() {
        let url = new URL(ssoURL);

        if (localStorage.getItem("usePermsLink") === "YES") {
            url = new URL(ssoPermsURL);
            localStorage.removeItem("usePermsLink")
        }

        params.forEach((value, key) => {
            if (key !== "req") {
                url.searchParams.append(key, value)
            }
        })

        return url.href
    }


    return (
        <>
            <BaseButton fullWidth onMouseOver={onHover} onMouseOut={onStopHover} onClick={() => {window.open(createURL(), "_blank")}}>
                <Grid container direction={"row"}>
                    <Grid item container direction={"row"} style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }} xs={1}>
                    </Grid>
                    <Grid item xs={11}>
                        {buttonText}
                    </Grid>
                </Grid>
            </BaseButton>
        </>
    )
}

SSOButton.defaultProps = {
    forwardParams: true
}
