import React from "react";
import { render } from 'react-dom'; 
import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = document.getElementById('root'); // <- This is the //correct method call for React version 17
render(  
  <React.StrictMode>    
    <App />  
  </React.StrictMode>,  root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
