import {Grid} from "@mui/material";
import {SamaggiTypography} from "../../../common/SamaggiTypography";
import {SSOButton} from "../../../common/SSOButton";
import React, {useEffect} from "react";
import {BasicProps} from "../../../Main";

export const HubLogin: React.FC<BasicProps> = (props) => {

    useEffect(() => {
        props.setShowCover(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Grid container direction={"column"} spacing={4}>
                <Grid item container direction={"column"} spacing={1}>
                    <Grid item>
                        <SamaggiTypography bold large center>Sign In to Continue</SamaggiTypography>
                    </Grid>
                    <Grid item>
                        <SamaggiTypography center>Please sign in to access the Careers Fair Application System.</SamaggiTypography>
                    </Grid>
                </Grid>
                <Grid item>
                    <SSOButton/>
                </Grid>
            </Grid>
        </>
    )
}

export default HubLogin