import SamaggiPaper, {SamaggiPaperProps} from "./SamaggiPaper";
import React, {PropsWithChildren} from "react";

export interface SamaggiPageContainerProps {
    noContainer?: boolean
}

export const SamaggiPageContainer: React.FC<SamaggiPageContainerProps & SamaggiPaperProps & PropsWithChildren> = (props) => {
    const {noContainer: _, ...rest} = props;

    if (props.noContainer) {
        return (
            <div style={{padding: "10px", paddingTop: "40px"}}>
                {props.children}
            </div>
        )
    } else {
        return (
            <SamaggiPaper {...rest}>
                {props.children}
            </SamaggiPaper>
        )
    }
}