import { Grid, Typography, Button, ThemeProvider } from "@mui/material"
import theme from "../../../theme";
import SACCPaper from "../../../common/SACC/SACCPaper";
import Upload from "../../../common/SACC/CareersFairContext/Upload";
import {useEffect, useState} from "react";
import {UUIDv4} from "../../../support";
import AWS from "aws-sdk";
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";

const sectionStyle = {
    fontWeight: "700",
    fontSize: "32px",
    color: "#FB8A3E"
}

const tickStyle = {
    color: "#a9a9a9",
    '&.Mui-checked': {
        color: "#FB8A3E",
    },
    width: 30,
    height: 30,
    marginRight: 10,
}

const answerStyle = {
    fontWeight : "400",
    fontSize : "14px",
    padding : "20px",
    border: "2px solid #A2A2A2",
    borderRadius : "15px",
    width: "100%",
    height: 200
}

const useStyles = makeStyles(() => ({
    logoffButton: {
        background: "#FB8A3E",
        color: "white",
        width: "100%",
        padding: "15px",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        "&:hover": {
            background: "#FB8A3E",
        },
    },
    applicationTitle: {
        color: "#FB8A3E",
        fontWeight: 700,
        fontSize: "48px",
    },
    squareContainer: {
        padding: "10px",
        boxSizing: "border-box",
        overflow: "auto",
        maxWidth: "100%",
        aspectRatio: 1
    },
    squareSmall: {
        padding: "10px",
        boxSizing: "border-box",
        overflow: "auto",
        maxWidth: "30%",
        aspectRatio: 1
    },
}));

const ApplySubmit = (props) => {

    const {submitApplication, companyInfo, getRole} = useAuth();
    const classes = useStyles();

    const [roleData, setRoleData] = useState({});
    const [companyData, setCompanyData] = useState({});

    function load(roleID) {
        getRole(roleID).then(async (roleRes) => {
            props.setShowCover(false);
            const data = await roleRes.json();
            if (roleRes.status === 200) {
                setRoleData(data.data);
                companyInfo(data.data["company-id"]).then(async (companyRes) => {
                    const data = await companyRes.json();
                    if (companyRes.status === 200) {
                        setCompanyData(data.data);
                    } else {
                        alert(data.data["reason"]);
                    }
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [additionalText, setAdditionalText] = useState("");
    const [checked, setChecked] = useState(false);

    function onChangeFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }

    const [submitting, setSubmitting] = useState(false);

    function submit() {
        const cfasUserCvBucket = "cfas-user-cv-bucket";
        const bucketRegion = "eu-west-2";
        const IdentityPoolId = "eu-west-2:67102926-3c2f-4e94-b91a-8058ee31d8b8";

        if (!checked) {
            alert("You must agree to sharing information before submitting.");
            return
        }

        if (submitting) {
            return
        }

        setSubmitting(true);
        props.setShowCover(true);

        let link;

        if (fileName !== "") {
            AWS.config.update({
                region: bucketRegion,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: IdentityPoolId
                })
            });

            const key = encodeURI(UUIDv4() + fileName.replace(/[^A-Za-z0-9.]/g, ""));

            const upload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: cfasUserCvBucket,
                    Key: key,
                    Body: file
                }
            });

            const uploadPromise = upload.promise();

            link = "https://" + cfasUserCvBucket + ".s3.eu-west-2.amazonaws.com/" + key;

            uploadPromise.then(
                function () { saveToDB(link) },
                function(err) {
                    alert(err);
                    setSubmitting(false);
                    props.setShowCover(false);
                    return alert("There was an error uploading your photo: " + err.message);
                }
            );
        } else {
            link = "X";
            saveToDB(link);
        }


    }

    function saveToDB(link) {
        const urlParams = new URLSearchParams(window.location.search);
        const positionID = urlParams.get('roleID');

        submitApplication(positionID, additionalText, link).then(async (res) => {
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                alert("Application Submitted Successfully");
                window.location = "/cfas/track/home";
            } else {
                setSubmitting(false);
                props.setShowCover(false);
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            setSubmitting(false);
            props.setShowCover(false);
            console.log(error)
        })
    }

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            const urlParams = new URLSearchParams(window.location.search);
            const positionID = urlParams.get('roleID');
            load(positionID);
            props.setShowCover(true);
            setFirstLoad(false);
        }
    })

    const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return(
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" alignItems="center" padding="5%" spacing={5} rowSpacing={10}>
                <CFASHeader classes={classes} secondaryURL={`/cfas/apply/company?companyID=${companyData['company-id']}`} secondaryText={"Back to List"}>
                    CFAS Apply
                </CFASHeader>
                <Grid container item direction="row" alignItems="center" justifyItems={"center"} spacing={3} columnSpacing={4}>
                    <Grid item xs={12}>
                        <SACCPaper>
                            <Grid container padding={smallScreen ? 4 : 1}>
                                <Grid item marginBottom={3} xs={12} container direction={"row"} justifyContent={"center"}>
                                    <Grid item container className={smallScreen ? classes.squareSmall : classes.squareContainer} direction={"row"} alignContent={"center"} justifyContent={"center"} margin={4}>
                                        <Grid item style={largeScreen ?  {maxWidth: "100%"} : (smallScreen ? {maxWidth: "100%"} : {maxWidth: "100%"})}>
                                            <img src={companyData["logo"]} style={{maxWidth: "100%"}} alt="Company Logo"></img>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container direction={"column"} xs={12} justifyContent={"center"} rowSpacing={3}>
                                    <Grid item>
                                        <Typography sx={{fontSize: "40px", fontWeight : "600", width: "100%"}}>
                                            {roleData["position-name"]}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {roleData["position-description"] !== undefined && <Typography sx={{fontSize: "24px", fontWeight : "500", whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: roleData["position-description"].replace("//", "")}}>
                                        </Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                </Grid>
                <Grid container item direction="column" spacing={3}>
                    <Grid item>
                        <Typography sx={sectionStyle}>Questions</Typography>
                    </Grid>
                    <Grid item>
                        <SACCPaper>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography sx={{fontWeight : "600", fontSize :"20px"}}>{roleData["additional-question"] ? roleData["additional-question"] : "Please write any additional information for this application here (if any)."}</Typography>
                                </Grid>
                                <Grid item sx={{paddingRight: 5}}>
                                    <textarea style={answerStyle} value={additionalText} onChange={(e) => setAdditionalText(e.target.value)}></textarea>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                </Grid>
                {roleData["cover-letter"] &&
                    <Grid container item direction="column" spacing={3}>
                        <Grid item>
                            <Typography sx={sectionStyle}>Cover letter</Typography>
                        </Grid>
                        <Grid item>
                            <SACCPaper>
                                <Upload cover handleChange={e => onChangeFile(e)}></Upload>
                            </SACCPaper>
                        </Grid>
                    </Grid>
                }
                <Grid container item direction="row" justifyContent="center" alignContent={"center"}>
                    <Grid item xs={1}>
                        <input style={tickStyle} type="checkbox" onClick={() => setChecked(!checked)} checked={checked}></input>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography sx={{color : "#a9a9a9", fontSize:"24px", fontWeight : "600"}}>By ticking this checkbox you are agreeing to share your profile data, CV and all data entered above to Samaggi Samagom and {companyData["company-name"]}.</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button sx={{color : "white", background: "#FB8A3E", borderRadius : "15px", fontWeight : "600", fontSize : "24px", padding : "10px 0", width: "670px"}} onClick={submit}>{submitting ? "Submitting" : "Submit"}</Button>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

ApplySubmit.defaultProps = {
    company : "ApplyCompany name",
    compDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    question : "Lorem ipsum dolor sit amet?",
    answer  : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."

}

export default ApplySubmit;